import client from "@/services/rest/client";

export function fetchCategories() {
  const method = "get";
  const url = "/public/categories";

  return client({ method, url });
}

export function fetchGame(slug) {
  const method = "get";
  const url = `/public/games/${slug}`;

  return client({ method, url });
};

export function fetchCategory(params) {
  const method = "get";
  const url = `/public/categories/${params.slug}?with-games=${params.withGames}`;

  return client({ method, url });
};

export function fetchGames(params) {
  const method = "get";
  const url = "/public/games";

  return client({ method, url, params });
};

export function fetchProviders(params) {
  const method = "get";
  const url = "/public/providers";

  return client({ method, url, params });
};

export function fetchProvider(slug) {
  const method = "get";
  const url = `/public/providers/${slug}`;

  return client({ method, url });
};
