import { type RouteRecordRaw } from "vue-router";
import SurvivorView from "../views/SurvivorView.vue";
import SurvivorLayout from "../layouts/SurvivorLayout.vue";

export const survivorRoutes: RouteRecordRaw = {
  path: "/survivor",
  component: SurvivorLayout,
  children: [
    {
      path: "",
      name: "survivor",
      component: SurvivorView,
    },
  ],
};
