import client from "@/services/rest/client";

export function fetchProviderBySlug(slug) {
  const method = "get";
  const url = `/public/providers/${slug}`;

  return client({ method, url });
};

export function fetchGames(params) {
  const method = "get";
  const url = "/public/games";

  return client({ method, url, params });
};
