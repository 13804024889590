<script setup>
import CategoryRow from "@/casino/components/CategoryRow.vue"
import ProviderRow from "@/casino/components/ProviderRow.vue"

defineProps({
  categories: {
    type: Array,
    required: true,
  },
  providers: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  lastPage: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(["update:data"])

function updateData(page) {
  emit("update:data", page)
}
</script>

<template>
  <ProviderRow
    :providers="providers"
    :search="filters.search"
    :current-page="currentPage"
    :last-page="lastPage"
    @update:data="updateData"
  />

  <CategoryRow v-for="category in categories" :key="category.slug" :category="category" :search="filters.search" />
</template>
