import type { RouteRecordRaw } from "vue-router"
import CasinoLayout from "@/casino/layouts/CasinoLayout.vue"
import CasinoHome from "@/casino/views/CasinoHome.vue"
import CasinoLobby from "@/casino/views/CasinoLobby.vue"
import CategoryGames from "@/casino/views/CategoryGames.vue"
import GameView from "@/casino/views/GameView.vue"
import ProviderGames from "@/casino/views/ProviderGames.vue"
import Providers from "@/casino/views/Providers.vue"

export const casinoRoutes: RouteRecordRaw = {
  path: "/casino",
  name: "casino",
  redirect: "/casino/home",
  component: CasinoLayout,
  children: [
    {
      path: "home",
      name: "casino.home",
      component: CasinoHome,
      children: [
        {
          path: "categories/:slug",
          name: "casino.category",
          component: CategoryGames,
          props: true,
        },
        {
          path: "providers",
          name: "casino.providers",
          component: Providers,
        },
        {
          path: "providers/:slug",
          name: "casino.provider",
          component: ProviderGames,
          props: true,
        },
        {
          path: "",
          name: "casino.lobby",
          component: CasinoLobby,
        },
      ],
    },
    {
      path: "game/:slug",
      name: "casino.game",
      component: GameView,
      props: true,
      // meta: { requiresAuth: true },
    },
  ],
}
