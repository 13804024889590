import axios from "axios";
import { useBetStore } from "../../../store";
import { useAuth } from "../../user-account/composables/useAuth";
import auth from "./interceptors/auth";
import { blocked } from "./interceptors/geo-blocked";
import { t } from "@/app/composables/useI18n";
import { toast } from "@/app/utils/notification";

const baseURL = `${import.meta.env.VITE_API_URL}api`;

const client = axios.create({
  baseURL,
  timeout: 30000,
  headers: { "Content-Type": "application/json", "Accept": "application/json" },
  responseType: "json",
  responseEncoding: "utf8",
});

client.interceptors.request.use(auth);
client.interceptors.response.use(blocked);

client.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      const { data, status } = error.response;
      const { message, i18n } = data || {};

      // Show toast warning if a message exists
      message && status !== 401 && toast.warn(i18n ? t(`errors.${i18n}`) : message);

      // Handle unauthorized access
      if (status === 401) {
        const auth = useAuth();
        const betStore = useBetStore();
        auth.logout();
        betStore.setTimeValue(false);
      }
    }

    return Promise.reject(error.response.data); // Reject the promise with the error
  },
);

export default client;
