import { useGlobalLoader } from "@/app/composables/useGlobalLoader"
import { usePromotion } from "@/app/composables/usePromotion"
import { toast } from "@/app/utils/notification"
import { api } from "@/services"
import { useAuth } from "@/user-account/composables/useAuth"
import { useUserService } from "@/user-account/composables/useUserService.ts"
import { ref } from "vue"

const { activeBonus } = usePromotion()

const { enableLoader } = useGlobalLoader()
const { isAuthenticated } = useAuth()
const { activeWallet, bonusWallet } = useUserService()
const selectedCurrency = ref("USD")
const loading = ref(false)

const isGameSession = ref(false)

export function useGameHelper() {
  async function startGame(game, isDemo, handleLogin) {
    if (!isAuthenticated() && !isDemo) {
      handleLogin()
      return
    }
    enableLoader(3000)
    isGameSession.value = true
    loading.value = true

    // Get wallet id
    const walletId = isBonusGame(game) ? bonusWallet.value.id : activeWallet.value.id

    const data = await api.casino.gameInit({
      gameId: game.value.id,
      walletId,
      isDemo,
      cur: selectedCurrency.value,
    })

    if (data.redirect_url === null) {
      toast.warn(`This game can not be played${isDemo ? " in demo mode" : ""}`)
    }
    else {
      game.value.redirectUrl = data.redirect_url
    }
    loading.value = false
  }

  function isBonusGame(game) {
    if (!bonusWallet.value) {
      return false
    }

    if (!activeBonus.value?.allowedGameIds) {
      return false
    }

    const providers = Object.values(activeBonus.value.allowedGameIds)

    const allowedProviderIds = providers.reduce((acc, provider) => {
      if (Array.isArray(provider.games) && provider.games.length === 0) {
        acc.push(provider.id)
      }
      return acc
    }, [])

    const allowedGameIds = providers.reduce((acc, provider) => {
      const games = Object.values(provider.games)
      const gameIds = games.map(game => game.id)
      acc.push(...gameIds)
      return acc
    }, [])

    return allowedProviderIds.includes(game.value.providerId) || allowedGameIds.includes(game.value.id)
  }

  return {
    startGame,
    isBonusGame,
    isGameSession,
    selectedCurrency,
  }
}
