// src/utils/timerUtils.js
import { computed, onUnmounted, ref } from "vue"

export function useStartTimer(entryCloseTime, eventEndTime = null) {
  const second = ref(0)
  const minutes = ref(0)
  const hour = ref(0)
  const status = ref(null)

  // Function to calculate time difference and update refs
  const updateTimeDifference = () => {
    const now = new Date().getTime()
    const targetTime = new Date(entryCloseTime).getTime()
    const endTime = eventEndTime ? new Date(eventEndTime).getTime() : null

    // Check if entry is closed but event is still ongoing
    if (now > targetTime && endTime && now < endTime) {
      status.value = "In Progress"
      return
    }

    // Check if both entry is closed and event has ended
    if (now > targetTime && endTime && now > endTime) {
      status.value = "Finished"
      return
    }

    // Calculate countdown if entry is still open
    if (now < targetTime) {
      status.value = "Countdown"
      const timeDifference = targetTime - now
      const totalSeconds = Math.floor(timeDifference / 1000)

      second.value = totalSeconds % 60
      minutes.value = Math.floor((totalSeconds % 3600) / 60)
      hour.value = Math.floor(totalSeconds / 3600)
    }
    else {
      // Entry is closed but no eventEndTime provided
      status.value = eventEndTime ? "In Progress" : "Closed"
    }
  }

  // Initial calculation
  updateTimeDifference()

  // Create interval ref to track it for cleanup
  const intervalId = setInterval(updateTimeDifference, 1000)

  // Cleanup interval when component unmounts
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  })

  // Return a string value directly
  return computed(() => {
    if (status.value === "Countdown") {
      return `${hour.value.toString().padStart(2, "0")}hr : ${minutes.value
        .toString()
        .padStart(2, "0")}m : ${second.value.toString().padStart(2, "0")}s`
    }
    else {
      return status.value
    }
  })
}
