<script setup>
import BaseLayout from "@/app/layouts/BaseLayout.vue"
import { ref } from "vue"

const categories = ref([])

function onCategoryUpdate(data) {
  categories.value = data
}
</script>

<template>
  <BaseLayout :categories="categories">
    <router-view @update:categories="onCategoryUpdate" />
  </BaseLayout>
</template>
