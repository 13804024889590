<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { t } from "@/app/composables/useI18n"

import { useUserService } from "@/user-account/composables/useUserService.ts"
import { rules } from "@/user-account/utils/input-rules/otp"
import { validate } from "@/user-account/utils/input-rules/validation"
import QRCode from "qrcode"
import { computed, onMounted, ref } from "vue"
import { disable2fa, enable2fa, getQRCode } from "../../../user-account/api/index"

const { user } = useUserService()

const qrCode = ref("")
// const qrCode1 = ref("");
const google2faSecret = ref("")
const otp = ref("")

async function getQrCode() {
  try {
    const { data: qrCodeData } = await getQRCode()
    google2faSecret.value = qrCodeData.google2faSecret
    // Using QR code URL directly from API response
    qrCode.value = await QRCode.toDataURL(qrCodeData.qrCodeUrl)
  }
  catch {
    // setErrors(form, errors);
  }
  // const data = await api.auth.qrCode();
  // google2faSecret.value = data.google2faSecret;
  // // qrCode.value = data.qrCode;
  // const s = `otpauth://totp/${import.meta.env.VITE_APP_NAME}: ${user.value.email}
  //   ?secret=${google2faSecret.value}&algorithm=SHA1&digits=6`;
  // qrCode.value = await QRCode.toDataURL(s);
}

const is2faEnabled = computed(() => {
  const data = user.value
  return data?.auth["2fa"]?.google?.enabled ?? false
})

async function enable() {
  if (validate([{ value: otp.value, rules }])) {
    // api.auth.enable2fa({ otp: otp.value });

    try {
      await enable2fa({ otp: otp.value })
      otp.value = ""
      user.value.auth["2fa"].google.enabled = true
    }
    catch {
      // setErrors(form, errors);
    }
  }
}

async function disable() {
  // if (validate([{ value: otp.value, rules }])) {
  //   api.auth.disable2fa({ otp: otp.value });
  //   // otp.value = "";
  // }

  try {
    await disable2fa({ otp: otp.value })
    otp.value = ""
    user.value.auth["2fa"].google.enabled = false
    getQrCode()
  }
  catch {
    // setErrors(form, errors);
  }
}

onMounted(() => {
  if (!is2faEnabled.value) {
    getQrCode()
  }
})
</script>

<template>
  <div class="manage-2fa-wrapper">
    <h2>{{ t("account.2fa") }}</h2>
    <div>
      <div>
        <div v-if="is2faEnabled" class="desc">
          <p>{{ t("account.2fa_is_enabled") }}</p>
          <p>{{ t("account.click_to_disable") }}</p>
          <!-- <p class="mt-2">
            {{ t("account.you_can_reenable") }}
          </p> -->
        </div>
        <div v-else class="desc">
          <p>{{ t("account.2fa_add_details") }}</p>
          <p>{{ `${t("account.qr_code")}: ${google2faSecret}` }}</p>
          <img v-if="qrCode" :src="qrCode" class="qr-code-image" alt="QR Code">
          <!-- <img v-if="qrCode1" :src="qrCode1" class="qr-code-image" alt="QR Code"> -->
        </div>

        <BaseTextField
          v-model="otp"
          class="otp-input"
          required
          :label="t('account.type_otp')"
          :rules="rules"
        />
        <BaseButton class="alight wide high" @click="is2faEnabled ? disable() : enable()">
          {{ is2faEnabled ? t("account.disable_2fa") : t("account.enable_2fa") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.manage-2fa-wrapper {
  .otp-input {
    margin: 2em 0;
  }
  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .qr-code-image {
      // width: 250px;
      margin: 1em;
      background-color: #ffffff;

      .tablet & {
        width: 150px;
      }
    }

    .tablet & p {
      font-size: 15px !important;
    }
  }
}

// p {
//   margin: 15px;
// }
</style>
