<script setup>
import { ellipsis } from "@/app/utils/strings"

const props = defineProps({
  leagueName: {
    type: String,
    required: true,
  },
  marketGroups: {
    type: Array,
    default: () => [],
  },
})
</script>

<template>
  <div class="league-subheader">
    <div class="league-name">
      {{ ellipsis(leagueName, 23) }}
    </div>

    <div class="market-groups">
      <div
        v-for="(marketGroup, i) in props.marketGroups"
        :key="i"
        class="market-group"
        :class="{
          'three-way': marketGroup.markets[marketGroup.activeIndex].headers.length === 3,
        }"
      >
        <div
          v-for="header in marketGroup.markets[marketGroup.activeIndex].headers"
          :key="header"
          class="market-option"
        >
          {{ header }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "../assets/scss/market-groups.scss" ;

.league-subheader {
  padding: 5px 0;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background-color: var(--be-small-card-bg);
  .league-name {
    /*padding-left: 12px;*/
    padding: 2px 7px;
    font-size: 12px;
    text-align: left;
  }
  .market-group {
    display: flex;
    font-size: 12px;
    padding: 3px 0 0;
  }
}
</style>
