import { type Router } from "vue-router";
import { useGameHelper } from "@/casino/composables/useGameHelper.js";
import { useConfig } from "@/app/composables/useConfig";
import { useAuth } from "@/user-account/composables/useAuth";

const { appDivision, appDivisions, geoblocked } = useConfig();
const { isAuthenticated } = useAuth();
const { isGameSession } = useGameHelper();

export function registerRouterGuards(router: Router) {
  router.beforeEach((to, from, next) => {
    // 404
    if (to.matched.length === 0) {
      return next({ name: "not-found" });
    }

    // Close active game session flag
    isGameSession.value = false;

    // Website division selection
    if ((to.name as string).includes("casino")) {
      if (!appDivisions.value.includes("casino")) {
        return next({ name: "sportsbook" });
      }
      appDivision.value = "casino";
    } else if ((to.name as string).includes("sports")) {
      if (!appDivisions.value.includes("sportsbook")) {
        return next({ name: "casino" });
      }
      appDivision.value = "sportsbook";
    } else if ((to.name as string).includes("survivor")) {
      if (!appDivisions.value.includes("survivor")) {
        return next({ name: "survivor" });
      }
      appDivision.value = "survivor";
    } else if ((to.name as string).includes("virtualGames")) {
      if (!appDivisions.value.includes("virtualGames")) {
        return next({ name: "casino" });
      }
      appDivision.value = "virtualGames";
    }

    // Geo-blocking
    if (["login", "register"].includes(to.name as string) && geoblocked.value) {
      router.push({ name: "geo-blocked" });
    }

    // Do not allow user to visit login page or register page if they are logged in
    if (isAuthenticated() && to.meta.excludesAuth) {
      return next({ name: "/" });
    }

    next(); // All views with public access
  });
}
