<script setup lang="ts">
import type { PropType, Ref } from "vue"

import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { useSportsdata } from "@/sportsbook/composables/useSportsdata"
import { useAuth } from "@/user-account/composables/useAuth"
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService"
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useBetStore } from "../../../../store"
import LanguageSelect from "../LanguageSelect.vue"
import SidebarSection from "./SidebarSection.vue"

const props = defineProps({
  categories: {
    type: Array as PropType<{
      id: number | string
      name: string
      slug: string
      image?: { url: string } | null
    }[]>,
    default: () => [],
  },
})

const { isDrawerOpen, appDivisions, appDivision } = useConfig()

const { sports } = useSportsdata()
const { virtualGames } = useVirtualGamesService()

const { isAuthenticated } = useAuth()
const { t } = useI18n()

const language = ref(false)

const rootItems: SidebarMenuItem[] = []
const store = useBetStore()
const baseItems = computed(() => [
  {
    id: -101,
    title: t("sportsbook.mybets"),
    icon: "bets",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => {
      if (isAuthenticated()) {
        store.handleShowBetModal()
        store.setSection("bet")
      }
      else {
        store.handleShowLogin()
      }
    },
  },
])

const supportItems = computed(() => [
  {
    id: -101,
    title: t("general.live_support"),
    icon: "live",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => store.handleShowChatModal(),
  },
  {
    id: 103,
    title: t("general.language"),
    icon: "globe",
    route: { name: "" },
    click: true,
    imageUrl: null,
    handleClick: () => (language.value = !language.value),
  },
])

if (appDivisions.value.includes("sportsbook")) {
  rootItems.push({
    id: -101,
    title: t("general.sports"),
    icon: "sports",
    imageUrl: null,
    route: { name: "sportsbook" },
    click: false,
    handleClick: undefined,
  })
}
if (appDivisions.value.includes("casino")) {
  rootItems.push({
    id: -102,
    title: t("general.casino"),
    icon: "lobby",
    imageUrl: null,
    route: { name: "casino" },
    click: false,
    handleClick: undefined,
  })
  rootItems.push({
    id: -101,
    title: t("general.virtualGames"),
    icon: "sports",
    imageUrl: null,
    route: { name: "virtualGames" },
    click: false,
    handleClick: undefined,
  })
}

const casinoItems: Ref<SidebarMenuItem[]> = computed(() => {
  return props.categories.map((category) => {
    return {
      id: Number(category.id),
      title: category.name,
      icon: null,
      route: {
        name: "casino.category",
        params: { slug: category.slug },
      },
      imageUrl: category.image?.url ?? null,
      click: false,
      handleClick: undefined,
    }
  })
})

const sportbookItems: Ref<SidebarMenuItem[]> = computed(() => {
  return sports.value.map((sport) => {
    return {
      id: sport.id,
      title: t(`sports.${sport.slug}`),
      icon: sport.icon,
      imageUrl: null,
      route: {
        name: !sport.showAllMatches ? "sports.today" : "sport.competition",
        params: {
          sportSlug: sport.slug,
        },
      },
      click: false,
      handleClick: undefined,
    }
  })
})

const virtualGamesItems: Ref<SidebarMenuItem[]> = computed(() => {
  return virtualGames.value.map((game) => {
    return {
      id: game?.id,
      title: t(`general.${game?.code}`),
      icon: game?.code,
      imageUrl: null,
      route: {
        name: "virtualGames.game",
        params: { code: game?.code },
      },
      click: false,
      handleClick: undefined,
    }
  })
})

const screenWidth = ref(window.innerWidth)
const isMobile = computed(() => screenWidth.value <= 568)
const DrawerValue = ref(screenWidth.value >= 568 ? true : isDrawerOpen.value)

function handleResize() {
  screenWidth.value = window.innerWidth
  if (screenWidth.value > 568) {
    isDrawerOpen.value = DrawerValue.value
  }
}

// Toggle drawer visibility
function handleShowDrawer() {
  if (isAuthenticated()) {
    store.handleShowBetModal(false)
  }

  isDrawerOpen.value = !isDrawerOpen.value

  // Handle body overflow for mobile
  if (isMobile.value) {
    isDrawerOpen.value
      ? (window.document.body.style.overflow = "auto")
      : (window.document.body.style.overflow = "hidden")
  }
}

// Handle clicks outside the sidebar
function handleClickOutside(event: MouseEvent) {
  if (isMobile.value && isDrawerOpen.value) {
    const sidebarEl = document.querySelector(".side-bar-col")
    if (sidebarEl && !sidebarEl.contains(event.target as Node)) {
      // Close sidebar only on mobile
      handleShowDrawer()
    }
  }
}

onMounted(() => {
  window.addEventListener("resize", handleResize)
  document.addEventListener("mousedown", handleClickOutside)
  handleResize() // Initial check

  // Default state based on device
  if (screenWidth.value <= 568) {
    isDrawerOpen.value = false // Initially closed on mobile
  }
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize)
  document.removeEventListener("mousedown", handleClickOutside)

  // Reset body overflow when component is destroyed
  window.document.body.style.overflow = "auto"
})

// Watch for changes in isDrawerOpen
// watch(isDrawerOpen, (newValue) => {
//   if (isMobile.value) {
//     DrawerValue.value = !newValue
//   }
// })

function handleDepostBet() {
  if (isAuthenticated()) {
    store.handleShowBetModal()
    store.setSection("wallet")
    store.setWalletTab("deposit")
  }
  else {
    store.handleShowLogin()
  }
}

const tooltipVisible = ref(false)
const tooltipPosition = ref({ x: 0, y: 0 })
const currentTooltipTarget = ref<string>("")

function updateTooltipPosition(event: MouseEvent) {
  const target = event.currentTarget as HTMLElement
  const rect = target.getBoundingClientRect()
  tooltipPosition.value = {
    x: rect.right,
    y: rect.top + (rect.height / 2),
  }
  currentTooltipTarget.value = target.closest(".deposit-btn")
    ? "deposit"
    : target.closest(".expand-btn-inner") ? "expand" : "collapse"
}

const getTooltipText = computed(() => {
  if (currentTooltipTarget.value === "deposit")
    return t("finance.deposit")
  if (currentTooltipTarget.value === "expand")
    return t("general.expand")
  return t("general.collapse")
})
</script>

<template>
  <v-card style="z-index: 11">
    <v-layout>
      <v-navigation-drawer
        v-model="DrawerValue"
        :expand-on-hover="false"
        :style="`top: 60px; border: none; width:${
          isDrawerOpen ? '' : '270px'
        }; transform:translateX(${
          isDrawerOpen ? '0%' : screenWidth <= 568 && !isDrawerOpen ? '-200%' : null
        })`"
        class="side-bar-col"
        :rail="screenWidth >= 568 ? isDrawerOpen : false"
      >
        <div v-if="language" class="modal-overlay" @click="() => (language = false)">
          <LanguageSelect
            :class="` ${!isDrawerOpen ? 'language-selector' : 'shrink-language-selector'}`"
            @close="() => (language = false)"
          />
        </div>
        <div class="wrapper" style="margin-bottom: 150px">
          <div v-if="isDrawerOpen && !isMobile" class="">
            <div
              class="expand-btn-inner"
              @mouseenter="tooltipVisible = true; updateTooltipPosition($event)"
              @mouseleave="tooltipVisible = false"
            >
              <BaseIcon
                class="hamburger"
                :name="isDrawerOpen ? 'shift' : 'menu-open'"
                size="24"
                color="#eee"
                @click="() => {
                  isDrawerOpen = !isDrawerOpen
                  tooltipVisible = false
                }"
              />
            </div>
          </div>
          <SidebarSection
            :menu-items="baseItems"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <SidebarSection
            v-if="appDivision === 'casino'"
            :menu-items="casinoItems"
            :title="t('general.casino')"
            :game-heading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <SidebarSection
            v-if="appDivision === 'sportsbook'"
            :menu-items="sportbookItems"
            :title="t('general.sports')"
            :game-heading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />
          <SidebarSection
            v-if="appDivision === 'virtualGames'"
            :menu-items="virtualGamesItems"
            :title="t('general.virtualGames')"
            :game-heading="true"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />

          <SidebarSection
            :menu-items="supportItems"
            :title="t('general.virtualGames')"
            :show-separator="screenWidth >= 568 ? !isDrawerOpen : true"
          />

          <div v-if="!isDrawerOpen" class="closer-container">
            <div
              class="closer"
              @mouseenter="tooltipVisible = true; updateTooltipPosition($event)"
              @mouseleave="tooltipVisible = false"
            >
              <BaseIcon
                class="hamburger"
                name="menu-open"
                size="24"
                color="#eee"
                @click="() => {
                  isDrawerOpen = !isDrawerOpen
                  tooltipVisible = false
                }"
              />
            </div>
          </div>
          <div class="deposit-btn" @click="handleDepostBet">
            <div
              :class="`${isDrawerOpen ? 'ml-0' : 'ml-_11'}`"
              @mouseenter="isDrawerOpen && (tooltipVisible = true) && updateTooltipPosition($event)"
              @mouseleave="isDrawerOpen && (tooltipVisible = false)"
            >
              <BaseIcon name="deposit" size="18" color="var(--be-primary-btn-label)" />
            </div>
            <div v-if="screenWidth >= 568 ? !isDrawerOpen : true">
              {{ t("finance.deposit") }}
            </div>
          </div>
        </div>
      </v-navigation-drawer>

      <!-- Backdrop overlay for mobile when sidebar is open -->
      <div
        v-if="isMobile && !isDrawerOpen"
        class="sidebar-backdrop"
        @click="handleShowDrawer"
      />
    </v-layout>
  </v-card>

  <Teleport to="body">
    <div
      v-if="tooltipVisible"
      class="tooltip-teleport"
      :style="`
        position: fixed;
        left: ${tooltipPosition.x + 10}px;
        top: ${tooltipPosition.y}px;
        z-index: 9999;
        transform: translateY(-50%);
        background-color: white;
        color: #000;
        padding: 6px 12px;
        border-radius: 6px;
        font-size: 14px;
        white-space: nowrap;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      `"
    >
      {{ getTooltipText }}
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/thin-scrollbar.scss";

.expand-btn {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  margin-bottom: 0;
  background: var(--be-small-card-bg);
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.closer-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  margin-bottom: 0;
}

.closer {
  background: var(--be-small-card-bg);
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;

}

.closer:hover {
  background: var(--be-divider);
}

.modal-overlay {
  position: fixed;
  top: -60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  width: 1000vh;
  height: 100vh;
  z-index: 99;
}

.side-bar-col {
  background: var(--be-big-card-bg);
  color: var(--be-primary-text-color);

}

.deposit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  height: 40px;
  background: var(--be-primary-btn);
  color: var(--be-primary-btn-label);
  margin: 10px;
  font-size: 14px;
  font-family: Rubik-med;
  opacity: 0.9;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.deposit-btn:hover {
  opacity: 1;
}
.tooltip-teleport {
  position: relative;
}

.tooltip-teleport::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.ml-0 {
  margin-left: 0px;
}
.ml-_11 {
  margin-left: -11px;
}

.language-selector {
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  left: 271px;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}

.shrink-language-selector {
  left: 55px;
  flex-shrink: 0;
  margin: 0 10px 0 0;
  position: absolute;
  top: 150px;
  width: 186px;

  background: var(--be-small-card-bg);
  overflow: auto;
}
.sidenav {
  margin-top: 67px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: calc(100% - 67px);
  background: var(--be-bg);
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
  z-index: 99;
  &.opened {
    transform: translateX(0);
  }
}
.wrapper{

}
@media screen and (min-width: 500px) {
  .wrapper {
    position: absolute;
    width: 100%;
    max-height: calc(100vh - 60px); // Adjust the height as needed
  overflow-y: auto;
  overflow-x: hidden;
  }

}
@media screen and (max-width:550px) {
  .modal-overlay{
    left: 100px;
  }
}
// .sidenav.loaded {
//   visibility: visible !important;
// }

.expand-btn-inner {
  background: var(--be-small-card-bg);
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  margin: 10px;
}

.expand-btn-inner:hover {
  background: var(--be-divider);
}
</style>
