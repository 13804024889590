<script setup>
import { useI18n } from "@/app/composables/useI18n"

import VuePdfEmbed from "vue-pdf-embed"

// optional styles
import "vue-pdf-embed/dist/styles/annotationLayer.css"
import "vue-pdf-embed/dist/styles/textLayer.css"

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  link: {
    type: String,
    // required: true,
  },
})
const emit = defineEmits(["close"])
const { t } = useI18n()
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="">
          </div>

          <div class="modal-body">
            <VuePdfEmbed annotation-layer text-layer :source="link" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}

h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 90%;
  margin: 0px auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: 13px auto;
  position: relative;
  width: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
}

.modal-default-button {
  float: right;
}

@media screen and (max-width: 500px) {
  .modal-container {
    width: 95%;
    padding: 39px 0px 20px 0px;
  }
  .modal-detail {
    padding: 15px;
  }

  .modal-body {
    padding: 0px;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
