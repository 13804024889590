<script setup>
import { computed, onMounted, ref } from "vue"
import { Carousel, Slide } from "vue3-carousel"
import "@splidejs/vue-splide/css"
import "vue3-carousel/dist/carousel.css"

import { useBetStore } from "/store"

const props = defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
  spliderOptions: {
    type: Object,
    default: () => ({
      rewind: true,
      perPage: 10,
      pagination: false,
      breakpoints: {
        600: {
          perPage: 3,
        },
        882: {
          perPage: 5,
        },
        1230: {
          perPage: 8,
        },
        1114: {
          perPage: 7,
        },
        1000: {
          perPage: 6,
        },
        1376: {
          perPage: 9,
        },
      },
    }),
  },
})
const emit = defineEmits(["slideClick"])

const store = useBetStore()
const isMobile = computed(() => store.getScreenWidth < 550)

const myCarousel = ref(null)
const currentSlide = ref(0)
let startX = 0

const config = {
  transition: 600,
  itemsToShow: isMobile.value ? 1 : 2,
  gap: 3,
  wrapAround: true,
}
const intervalId = ref(null)
function startInterval() {
  intervalId.value = setInterval(() => {
    changeSlides("next")
  }, 5000)
}
function stopInterval() {
  clearInterval(intervalId.value)
}

function changeSlides(direction) {
  currentSlide.value
    = direction === "next"
      ? (currentSlide.value + 1) % props.slides.length
      : currentSlide.value === 0
        ? props.slides.length - 1
        : currentSlide.value - 1

  myCarousel.value?.[direction]()
}

function handleTouchStart(event) {
  startX = event.touches[0].clientX
}
function handleTouchEnd(event) {
  const diff = startX - event.changedTouches[0].clientX

  if (Math.abs(diff) > 50) {
    changeSlides(diff > 0 ? "next" : "prev")
  }
}

function goToSlide(index) {
  currentSlide.value = index
  if (myCarousel.value) {
    myCarousel.value.slideTo(index)
  }
}
onMounted(() => {
  startInterval()
})

function handlePdfOpen(index, fileUrl) {
  if (index === currentSlide.value) {
    emit("slideClick", fileUrl)
  }
}
</script>

<template>
  <div
    v-if="slides.length > 1"
    class="carousel-container"
    @touchend="handleTouchEnd"
    @touchstart="handleTouchStart"
    @mouseenter="stopInterval"
    @mouseleave="startInterval"
  >
    <Carousel v-bind="config" ref="myCarousel">
      <Slide
        v-for="(image, index) in slides"
        :key="index"
        class="carousel__slide"
      >
        <img :src="image.url" alt="image" @click="handlePdfOpen(index, image.file?.url)">
      </Slide>

      <!-- Custom Navigation Buttons -->
      <template #addons>
        <div v-if="slides.length !== 0" class="prev-container">
          <button class="custom-prev" @click="changeSlides('prev')">
            <div class="left-colored-svg" />
          </button>
        </div>
        <div v-if="slides.length !== 0" class="next-container">
          <button class="custom-next" @click="changeSlides('next')">
            <div class="right-colored-svg" />
          </button>
        </div>

        <div class="dots">
          <button
            v-for="(image, index) in slides"
            :key="index"
            class="dot"
            :class="{ active: currentSlide === index }"
            @click="goToSlide(index)"
          />
        </div>
      </template>
    </Carousel>
  </div>
</template>

<style lang="scss" scoped>
@use "@/casino/assets/scss/casino-styles.scss" as *;

.carousel-container {
  position: relative;
}

.carousel {
  --vc-nav-background: rgba(255, 255, 255, 0.7);
  --vc-nav-border-radius: 100%;
}

img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.carousel__slide {
  padding: 0 5px;
  border-radius: 20px;
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 1;
}

.custom-prev:hover,
.custom-next:hover {
  opacity: 1;
}

.left-colored-svg {
  width: 17px;
  height: 17px;
  background-color: var(--be-primary-btn); /* Change the SVG color */
  mask-image: url("/assets/images/leftArrow.svg");
  -webkit-mask-image: url("/assets/images/leftArrow.svg"); /* For Safari */
}

.right-colored-svg {
  width: 17px;
  height: 17px;
  background-color: var(--be-primary-btn); /* Change the SVG color */
  mask-image: url("/assets/images/rightArrow.svg");
  -webkit-mask-image: url("/assets/images/rightArrow.svg"); /* For Safari */
}

.custom-prev {
  right: 20%;
}

.custom-next {
  left: 20%;
}

.prev-container {
  position: absolute;
  left: -1px;
  top: 0;
  width: 25%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(90deg, #071824 0%, rgba(7, 24, 36, 0) 100%);
}

.next-container {
  position: absolute;
  right: -1px;
  top: 0;
  width: 25%;
  height: 100%;
  border-radius: 16px;
  background: linear-gradient(270deg, #071824 0%, rgba(7, 24, 36, 0) 100%);
}

.dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    padding: 0;
    transition: all 0.5s ease-in-out;
  }

  .dot.active {
    background: var(--be-primary-btn);
    transform: scale(1.2);
  }
}
@media screen and (max-width: 500px) {
  .custom-next,
  .custom-prev,
  .next-container,
  .prev-container {
    display: none;
  }
  .dots {
    bottom: -15px;
  }
}
.list-wrapper {
  padding-bottom: 8px;
  margin-top: 20px;

  .list-title {
    display: flex;

    align-items: center;
    margin: 0 0 4px 0px;

    img {
      max-width: 24px;
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }
}

.provider {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232441;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  margin: auto;
  height: 80px;
  width: 160px;
  .mobile & {
    height: 55px;
    width: 110px;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    padding: 2px 5px;
  }
}
</style>
