<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useI18n } from "@/app/composables/useI18n"
import { router } from "@/app/router"
import { computed, nextTick, onMounted, ref, watch, watchEffect } from "vue"
import VueHorizontal from "vue-horizontal"
import { fetchGames } from "./api"
import GameCard from "./GameCard.vue"
import { useBetStore } from "/store.js"

const props = defineProps({
  category: {
    type: Object,
    default: null,
  },

  search: {
    type: String,
    required: true,
  },
})

const loading = ref(false)
const games = ref([])
const currentPage = ref(1)
const lastPage = ref(1)
const horizontal = ref(null)
const currentScrollPosition = ref(0)
const hasGames = computed(() => games.value.length > 0)

const store = useBetStore()

const isMobile = computed(() => {
  return store.getScreenWidth < 512
})

watch(() => props.search, () => {
  updateGames()
})

function saveScrollPosition() {
  if (horizontal.value) {
    const scrollContainer = horizontal.value.$el.querySelector(".vue-horizontal")
    if (scrollContainer) {
      currentScrollPosition.value = scrollContainer.scrollLeft
    }
  }
}

watchEffect(() => {
  if (horizontal.value) {
    setTimeout(() => {
      const scrollContainer = horizontal.value?.$el?.querySelector(".vue-horizontal")
      if (scrollContainer) {
        scrollContainer.scrollLeft = currentScrollPosition.value
      }
    }, 100) // Adjust timeout if needed
  }
})

async function restoreScrollPosition() {
  // Wait for the DOM to update with new items
  await nextTick()
  await nextTick() // Sometimes a second nextTick is needed

  if (horizontal.value) {
    const scrollContainer = horizontal.value.$el
    if (scrollContainer && currentScrollPosition.value > 0) {
      // Use setTimeout to ensure the browser has time to process the DOM changes
      setTimeout(() => {
        if (scrollContainer) {
          scrollContainer.scrollLeft = currentScrollPosition.value
        }
      }, 50)
    }
  }
}

watch(() => games.value.length, async (newLength, oldLength) => {
  if (newLength > oldLength && currentScrollPosition.value > 0) {
    await restoreScrollPosition()
  }
})

async function updateGames(page = 1, scrollPosition) {
  const params = {
    categories: [props.category.id],
    per_page: 20,
    page,
    device: isMobile.value ? "mobile" : "desktop",
  }

  if (props.search) {
    params.search = props.search
  }

  loading.value = true
  if (scrollPosition) {
    saveScrollPosition()
  }
  const { data } = await fetchGames(params)

  if (props.search) {
    games.value = data.data
  }
  else {
    games.value = [...games.value, ...data.data]
  }
  currentPage.value = data.meta.current_page
  lastPage.value = data.meta.last_page
  loading.value = false
}

onMounted(() => {
  updateGames()
})

const { t } = useI18n()

function prevSlide(event) {
  if (horizontal.value && typeof horizontal.value.prev === "function") {
    horizontal.value.prev(event)
  }
  else {
    console.error("Horizontal reference or next method is not defined")
  }
}

function nextSlide(event) {
  if (horizontal.value && typeof horizontal.value.next === "function") {
    horizontal.value.next(event)

    const isLastSlide = !horizontal.value.hasNext
    if (currentPage.value < lastPage.value && isLastSlide) {
      updateGames(currentPage.value + 1, true)
    }
  }
  else {
    console.error("Horizontal reference or next method is not defined")
  }
}

function handleViewAll(slug) {
  router.push({ name: "casino.category", params: { slug } })
}
</script>

<template>
  <section v-if="hasGames" class="list-wrapper">
    <div class="list-header">
      <router-link
        :to="{ name: 'casino.category', params: { slug: category.slug } }"
        class="list-title"
      >
        <img v-if="category.image" :src="category.image?.url" alt="" width="24" height="24">
        <BaseIcon v-else name="poker-cards" color="var(--be-disable-state)" />
        <span> {{ category.name }}</span>
      </router-link>
      <div v-if="hasGames" class="list-view" @click="() => handleViewAll(category.slug)">
        <div>{{ t("general.view-all") }}</div>
        <div :class="horizontal?.hasPrev ? '' : 'dim'" @click="(event) => prevSlide(event)">
          <img src="/assets/images/left-arrow-base-sliding.svg" alt="">
        </div>
        <div :class="!horizontal?.hasNext && currentPage === lastPage ? 'dim' : ''" @click="(event) => nextSlide(event)">
          <img src="/assets/images/right-arrow-base-sliding.svg" alt="">
        </div>
      </div>
    </div>
    <VueHorizontal v-if="hasGames" ref="horizontal" :button="false">
      <div v-for="game in games" :key="game.slug">
        <div class="game-wrapper">
          <GameCard :game="game" />
        </div>
      </div>
    </VueHorizontal>
    <div v-else class="no-games">
      <p>No games found</p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.no-games {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 16px;
  color: var(--be-disable-state);
}
.dim {
  opacity: 0.3;
}

.list-view {
  display: flex;
  gap: 0 20px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--be-primary-btn);
  font-family: Rubik;
}

.list-header {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 10px;
}

.list-wrapper {
  padding: 16px;
  margin: 10px 0px;
  background-color: var(--be-big-card-bg);
  border-radius: 8px;

  .list-title {
    display: flex;
    align-items: center;
    margin: 0 0 4px 2px;

    img {
      max-width: 24px;
      height: 24px;
    }

    span {
      margin: 4px 0 0 8px;
    }
  }

  .game-wrapper {
    display: flex;
    height: 100%;
    margin-right: 8px;
  }
}

@media screen and (max-width: 550px) {
  .list-wrapper {
    background: transparent;
    padding: 16px 0;
  }
}
</style>
