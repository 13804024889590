<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useI18n } from "@/app/composables/useI18n"
import { onMounted, ref, watch } from "vue"
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel"
import "vue3-carousel/dist/carousel.css"

const props = defineProps({
  sports: {
    type: Array,
    required: true,
  },
})
const emit = defineEmits(["selectSport"])
const { t } = useI18n()

const activeSport = ref(props.sports[0])

function selectSport(sport) {
  activeSport.value = sport
  emit("selectSport", sport)
}
</script>

<template>
  <!-- <div class="sport-list">
    <div
      v-for="sport in sports"
      :key="sport.id"
      class="sport-item"
      :class="{ active: activeSport === sport }"
      @click="selectSport(sport)"
    >
      <BaseIcon :name="sport.icon" />

      <span class="sport-name">{{ t(`sports.${sport.slug}`) }}</span>
    </div>
  </div> -->
  <div class="sport-list">
    <Carousel
      :transition="500"
      :breakpoints="{
        // 700px and up
        600: {
          itemsToShow: 5,
          snapAlign: 'center',
        },
        // 1024 and up
        300: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      }"
    >
      <Slide v-for="sport in sports" :key="sport.id">
        <div
          class="sport-item"
          :class="{ active: activeSport === sport }"
          @click="selectSport(sport)"
        >
          <BaseIcon
            :name="sport.icon"
            :filter="
              activeSport === sport ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))' : ''
            "
          />

          <span class="sport-name">{{ t(`sports.${sport.slug}`) }}</span>
        </div>
      </Slide>
      <template>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/thin-scrollbar.scss";
.sport-list {
  // display: flex;
  padding: 10px;
  // overflow-y: auto;
  background-color: var(--be-big-card-bg);
  // height: 72px;
  .sport-item {
    display: flex;
    // height: 40px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    // white-space: nowrap;
    padding: 10px 18px;
    margin-left: 14px;
    // min-width: 60px;
    overflow-wrap: break-word;
    color: var(--be-disable-state);

    cursor: pointer;
    font-size: 11px;
    text-align: center;
    // text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.3s ease-in;
    .sport-name {
      // margin: 3px 0;
      // height: 39px;
      padding-bottom: 11px;
      line-height: 14px;
      display: flex;
      height: 36px;
      align-items: center;
      text-transform: capitalize;
    }
    &.active {
      opacity: 1;
      // background: #14132c;
      color: var(--be-primary-btn);
      background: var(--be-small-card-bg);
      border-radius: 10px;
    }
    img {
      max-width: 24px;
      height: 24px;
    }
  }
}
</style>
