<script setup>
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import GameCard from "./GameCard.vue";

defineProps({
  name: {
    type: String,
    required: true,
  },
  games: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["load"]);

function onInfinite($state) {
  emit("load", $state);
}
</script>

<template>
  <div class="games-container">
    <div class="provider-header">
      {{ name }}
    </div>
    <div class="games-grid">
      <GameCard v-for="game in games" :key="game.slug" :game="game" />
    </div>
  </div>
  <div class="infinite-loading">
    <InfiniteLoading style="width: min-content;" @infinite="onInfinite">
      <template #complete>
        <p />
      </template>
    </InfiniteLoading>
  </div>
</template>

<style lang="scss" scoped>
.infinite-loading {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.provider-header {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.games-container {
  padding: 30px 16px;
  background-color: var(--be-big-card-bg);

  border-radius: 8px;
  margin-top: 10px;
}
.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
}

@media screen and (max-width: 550px) {
  .games-container {
    padding: 2px;
     background-color:transparent;
  }
  .games-grid {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(87px, 1fr));
    background: transparent;
    gap: 8px;
  }
}
</style>
