<script setup>
import { computed, onMounted, ref } from "vue";
import { getSurvivor, getSurvivors, postSurvivor } from "../../user-account/api/index";
import TournamentContent from "../components/TournamentContent.vue";
import GameCardContainer from "../components/GameCardContainer.vue";
import { useBetStore } from "../../../store";
import SubmitPrdictionModal from "../components/SubmitPredictionModal.vue";
import { useUserService } from "@/user-account/composables/useUserService";

const { isAuthenticated, activeWallet } = useUserService();
const store = useBetStore();

const data = ref([]);
const morePicks = ref(false);
const freeModal = ref(false);
const games = ref([]);
const survivorId = ref(null);
const selectedGames = ref([]);
const showSubmitModal = ref(false);
const entryCloseTime = ref(null);

const predictions = ref(null);
const locked = ref(null);
const league = ref(null);
const selectedItem = ref(null);

onMounted(async () => {
  const response = await getSurvivors();
  data.value = response.data.data;
});

function handleClose() {
  morePicks.value = false;
}

function handleFreeModal() {
  freeModal.value = true;
  morePicks.value = false;
}

function handleCloseFreeModal() {
  freeModal.value = false;
}
const isMobile = computed(() => {
  return store.getScreenWidth < 512;
});
async function handleSelectGame(item) {
  if (!isAuthenticated()) {
    store.handleShowLogin();
    // await router.push({ name: "login" });
    return;
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  survivorId.value = item.id;
  selectedItem.value = item;
  if (isMobile.value) {
    store.setIsShow(!store.isShow);
    morePicks.value = true;
  }
  const response = await getSurvivor(item.id);
  if (response) {
    predictions.value = response.data;
    selectedGames.value = response.data.predictions;
    morePicks.value = true;
  }
}

async function handleSubmitPredictions() {
  const response = await postSurvivor(survivorId.value, { predictions: selectedGames.value });
  if (response) {
    showSubmitModal.value = true;
  }
}

function handleCloseSubmitModal() {
  // if (isMobile.value) {
  //   isShow.value = true;
  // }
  store.setIsShow(true);
  showSubmitModal.value = false;
  morePicks.value = false;
}

async function handleConfirmSubmit() {
  showSubmitModal.value = false;
  if (isMobile.value) {
    store.setIsShow(true);
    morePicks.value = false;
  } else {
    morePicks.value = false;
  }
}
function handleCloseModal() {
  if (isMobile.value) {
    store.setIsShow(!store.isShow);
    morePicks.value = false;
  } else {
    morePicks.value = false;
  }
}
</script>

<template>
  <div class="container">
    <!-- Left side -->
    <div v-if="store.isShow" class="card tournament-card">
      <div class="card-header">
        <div class="mbl-heading desktop-none">
          Survival Style Sports Tournament Games. Play our free enter tournaments, and create your
          own games with friends tool
        </div>
        <div class="title">
          <img src="/assets/images/globe.svg" alt="globe" />
          Public Tournaments
        </div>
      </div>

      <div class="tournaments">
        <TournamentContent
          v-for="item in data"
          :key="item.id"
          :item="item"
          @select-game="handleSelectGame"
        />
      </div>
    </div>

    <!-- Right side -->
    <GameCardContainer
      v-model:selectedGames="selectedGames"
      :show-more-picks="morePicks"
      :item="selectedItem"
      :predictions="predictions"
      :entry-close-time="entryCloseTime"
      @close-modal="handleCloseModal"
      @free-modal="handleFreeModal"
      @submit="handleSubmitPredictions"
    />

    <SubmitPrdictionModal
      :show="showSubmitModal"
      @close="handleCloseSubmitModal"
      @confirm="handleConfirmSubmit"
    />
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  gap: 0 20px;
  padding: 20px 0px;
}

.tournaments {
  margin: 0 20px;
}

.card {
  background-color: var(--be-big-card-bg);
  border-radius: 20px;
  color: white;
  border: 1px solid var(--be-input-bg);
}

.tournament-card {
  flex: 1;
  max-width: 600px;
  position: relative;
}

.card-header {
  padding: 20px;

  .title {
    display: flex;
    align-items: center;
    gap: 0 10px;
    font-size: 16px;
    font-family: Poppins-med;
  }
}

.desktop-none {
  display: none;
}

@media (max-width: 550px) {
  .container {
    padding: 0px;
    margin-top: 20px;
    flex-direction: column;
  }

  .tournaments {
    margin: 0 15px;
  }

  .card-header {
    padding: 20px 15px;
  }

  .desktop-none {
    display: block;
  }

  .tournament-card {
    max-width: 100%;
    border-radius: 0px;
    border: none;
  }

  .mbl-heading {
    font-size: 14px;
    font-family: Poppins-med;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .mbl-none {
    display: none;
  }
}
</style>
