import { toast } from "@/app/utils/notification";
import { useBetStore } from "/store";
import { computed } from "vue";
export const handleShare = () => {
  const store = useBetStore();
  const isMobile = computed(() => store.getScreenWidth < 512);

  if (navigator.share && isMobile.value) {
    navigator
      .share({
        title: "Share!",
        url: window.location,
      })
      .then(() => console.log("Content shared successfully!"))
      .catch((error) => console.error("Error sharing:", error));
  }
  if (!isMobile.value) {
    const currentURL = window.location.href; // Get the current URL dynamically
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        toast.success("Link successfully copied!");
      })
      .catch((err) => {
        toast.error("Failed to copy", err);
      });
  }
};
