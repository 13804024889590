<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { api } from "@/services"
import { fetchCountriesData } from "@/user-account/api"
import { rules as emailRules } from "@/user-account/utils/input-rules/email"
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone"
import { computed, ref, watch } from "vue"

const emit = defineEmits(["submit", "resetPassword"])

const { t } = useI18n()
const { registrationFlow } = useConfig()

const countries = ref([])

const inputs = ref([
  {
    name: "password",
    value: "",
    type: "password",
    label: t("auth.password"),
    placeholder: t("auth.password"),
    rules: [],
    error: "",
    iconName: "eye-opened",
  },
])

watch(
  () => registrationFlow.value.type,
  (newType) => {
    if (["number_2fa", "number_2fa_with_email"].includes(newType)) {
      inputs.value.push({
        name: "phone",
        value: "",
        type: "tel",
        label: t("auth.phone"),
        placeholder: t("auth.phone"),
        rules: phoneRules,
        error: "",
      })
    }
    if (["number_2fa", "number_2fa_with_email"].includes(newType)) {
      inputs.value.push({
        name: "countryCode",
        value: "",
        type: "text",
      })
    }
    if (["email_2fa", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "email",
        value: "",
        type: "email",
        label: t("auth.email"),
        placeholder: t("auth.email"),
        rules: emailRules,
        error: "",
      })
    }
  },
  { immediate: true },
  { deep: true }, // Ensures the watcher runs initially to handle the current typeInfo value
)

function onReset() {
  emit("resetPassword")
}

const countryCodes = computed(() => {
  return countries.value.data?.filter(country => country.status).map(country => country.code)
})

async function fetchCountries() {
  try {
    const res = await fetchCountriesData()
    countries.value = res.data
  }
  catch (error) {
    console.error("Error fetching countries:", error)
  }
}

fetchCountries()
</script>

<template>
  <form @submit.prevent="$emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        v-if="['number_2fa', 'number_2fa_with_email'].includes(registrationFlow.type)"
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[1].value = $event"
        @country-changed="inputs[2].value = $event.iso2"
        @error="inputs[1].error = $event"
      />

      <BaseTextField
        v-if="['email_2fa', 'email_2fa_with_number'].includes(registrationFlow.type)"
        :model-value="inputs[inputs.length - 1].value"
        :rules="inputs[inputs.length - 1].rules"
        :label="inputs[inputs.length - 1].label"
        :placeholder="inputs[inputs.length - 1].placeholder"
        :type="inputs[inputs.length - 1].type"
        :icon-name="inputs[inputs.length - 1].iconName"
        :error="inputs[inputs.length - 1].error"
        @update:model-value="inputs[inputs.length - 1].value = $event"
        @error="inputs[inputs.length - 1].error = $event"
      />
      <BaseTextField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
        @error="inputs[0].error = $event"
      />
    </div>

    <BaseButton class="alight wide high" type="submit">
      {{ t("auth.login") }}
    </BaseButton>

    <div class="rest-prompt">
      <p>Forgot your password?</p>
    </div>

    <button class="rest-button" type="button" @click="onReset">
      Reset
    </button>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.rest-button {
  text-align: center;
  color: var(--be-primary-btn);
  font-size: 14px;
  width: 100%;
}
.rest-prompt {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
</style>
