<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js"
import { fetchCountriesData } from "@/user-account/api"
import { computed, ref } from "vue"

const props = defineProps({
  inputs: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(["submit", "toLogin"])

const { referAndEarn } = useReferAndEarn()
const { registrationFlow } = useConfig()

const { t } = useI18n()

const countries = ref([])

function onLogin() {
  emit("toLogin")
}

const countryCodes = computed(() => {
  return countries.value.data?.filter(country => country.status).map(country => country.code)
})

async function fetchCountries() {
  try {
    const res = await fetchCountriesData()
    countries.value = res.data
  }
  catch (error) {
    console.error("Error fetching countries:", error)
  }
}

fetchCountries()
</script>

<template>
  <form @submit.prevent="$emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        v-if="
          ['number_2fa', 'number_2fa_with_email', 'email_2fa_with_number'].includes(
            registrationFlow.type,
          )
        "
        :model-value="inputs[2].value"
        :rules="inputs[2].rules"
        :label="inputs[2].label"
        :placeholder="inputs[2].placeholder"
        :type="inputs[2].type"
        :icon-name="inputs[2].iconName"
        :error="inputs[2].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[2].value = $event"
        @country-changed="inputs[3].value = $event.iso2"
        @error="inputs[2].error = $event"
      />

      <BaseTextField
        v-if="
          ['email_2fa', 'number_2fa_with_email', 'email_2fa_with_number'].includes(
            registrationFlow.type,
          )
        "
        :model-value="inputs[inputs.length - 1].value"
        :rules="inputs[inputs.length - 1].rules"
        :label="inputs[inputs.length - 1].label"
        :placeholder="inputs[inputs.length - 1].placeholder"
        :type="inputs[inputs.length - 1].type"
        :icon-name="inputs[inputs.length - 1].iconName"
        :error="inputs[inputs.length - 1].error"
        @update:model-value="inputs[inputs.length - 1].value = $event"
        @error="inputs[inputs.length - 1].error = $event"
      />

      <BaseTextField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
        @error="inputs[0].error = $event"
      />

      <BaseTextField
        v-if="referAndEarn.enabled"
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        @update:model-value="inputs[1].value = $event"
        @error="inputs[1].error = $event"
      />
    </div>

    <BaseButton
      :disabled="inputs.some(input => input.error)"
      class="submit alight wide high"
      type="submit"
    >
      {{ registrationFlow?.allow_personal_info ? t("auth.next") : t("auth.register") }}
    </BaseButton>

    <div class="dont-have-account">
      <p>
        {{ t("account.already_account_label") }}
      </p>
    </div>

    <button class="login-now" type="button" @click="onLogin">
      {{ t("account.login_now_label") }}
    </button>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.terms {
  font-size: 14px;
  margin-bottom: 1rem;
}

.login-now {
  text-align: center;
  color: var(--be-primary-btn);
  font-size: 14px;
  width: 100%;
}
.dont-have-account {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
