<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { useBetStore } from "../../../store"

const { appDivisions, appDivision } = useConfig()
const store = useBetStore()
const { t } = useI18n()

function getIcon(division) {
  const icons = {
    casino: "poker-cards",
    sportsbook: "soccer",
    virtualGames: "e-sports",
    survivor: "survivors",
  }

  return icons[division]
}
store.setIsShow(true)
</script>

<template>
  <div v-if="store.isShow && appDivisions.length > 1" class="top-buttons">
    <router-link
      v-for="division in appDivisions"
      :key="division"
      :class="{ active: division === appDivision }"
      :to="{ name: division }"
    >
      <BaseIcon :name="getIcon(division)" size="18" />
      <span>{{ t(`general.${division}`) }}</span>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.top-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 0 4px 12px;
  a {
    flex-basis: 40%;
    gap: 3px;
    margin: 0 3px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none;
    background-color: var(--be-secondary-btn);
    &.active {
      color: var(--be-primary-btn-label);
      background: var(--be-primary-btn);
    }
  }
  .notebook &,
  .desktop & {
    display: none;
  }
}
@media screen and (min-width: 550px) {
  .top-buttons {
    margin: 0 8px 12px;
    a {
      gap: 5px;
      margin: 0 5px;
    }
  }
}
@media screen and (max-width: 400px) {
  .top-buttons {
    margin: 0 3px 8px;
    a {
      gap: 2px;
      margin: 0 2px;
      flex-basis: 50%;
    }
  }
}
</style>
