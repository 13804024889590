<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useI18n } from "@/app/composables/useI18n"
import { useRoute } from "@/app/router"
import { ref, watch } from "vue"
import ProviderSelector from "./ProviderSelector.vue"
import SortSelector from "./SortSelector.vue"

const props = defineProps({
  searchValue: {
    type: String,
    default: "",
  },
})

const emit = defineEmits(["search", "sort", "providers"])

const route = useRoute()
const { t } = useI18n()
const debounceTimeout = ref(null)
const localSearch = ref(props.searchValue)

// Keep localSearch in sync with props
watch(() => props.searchValue, (newValue) => {
  if (newValue !== localSearch.value) {
    localSearch.value = newValue
  }
})

function onProviders(value) {
  emit("providers", value)
}

function onSort(value) {
  emit("sort", value)
}

function onSearch(value) {
  clearTimeout(debounceTimeout.value)
  debounceTimeout.value = setTimeout(() => {
    emit("search", value)
  }, 600)
}
</script>

<template>
  <div class="filters">
    <div
      class="search"
    >
      <div class="prepend-inner">
        <BaseIcon name="magnify" size="20" />
      </div>

      <input
        v-model="localSearch"
        aria-autocomplete="none"
        :placeholder="t('casino.search')"
        type="text"
        class="search-input"
        @input="onSearch(localSearch)"
      >
    </div>
    <div
      v-if="route.name !== 'casino.lobby' && route.name !== 'casino.providers' && route.name !== `casino.provider`"
      class="provider-select-wrapper"
    >
      <ProviderSelector @providers="onProviders" />
      <SortSelector @sort="onSort" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters {
  width: 100%;
  margin-top: 10px;
  display: flex;
  .search {
    background-color: var(--be-big-card-bg);
    display: flex;
    gap: 10px;
    border: 1px solid var(--be-divider);
    border-radius: 8px;
    height: 47px;
    transition: border-color 0.2s ease;
    flex-grow : 1;

    &:hover {
      border-color: var(--be-primary-btn);
    }

    &:focus-within {
      border-color: var(--be-primary-btn);
    }
  }

  .search-full {
    flex-basis: 100%;
  }

  .search-input {
    width: 100%;
    outline: none;
    font-weight: 400;
    font-family: Rubik;
  }
  input::placeholder {
    color: var(--be-disable-state);
  }
  .prepend-inner {
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
    padding: 0px 0 0 10px;
    margin-right: 5px;
    color: var(--be-disable-state);
  }

  .provider-select-wrapper {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .filters {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }
}
</style>
