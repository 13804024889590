import { useBetStore } from "../../../store";
import { type JsonRpcResponseMessage } from "@/services/json-rpc/jsonrpc";
import { t } from "@/app/composables/useI18n";
import { toast } from "@/app/utils/notification";
import { useAuth } from "@/user-account/composables/useAuth.js";
import { router } from "@/app/router";
import { UnprocessableEntityError } from "@/exceptions";

export const notificationInterceptor = {
  process(data: JsonRpcResponseMessage) {
    // const message = data.error?.message || data.result?.message;

    if (data.result?.message) {
      const msg = data.result.data?.i18n
        ? t(`messages.${data.result.data?.i18n}`)
        : data.result.message;
      if (msg === "Bet was placed successfully" || msg === "Bets were placed successfully") {
        useBetStore()?.setTickets(true);
        useBetStore()?.setBetId(data?.result?.data);
      } else {
        toast.info(msg);
      }

      useBetStore()?.setErrorData(null);
      useBetStore().setTimeValue(false);
    }

    if (data.error?.message) {
      const msg = data.error.data?.i18n ? t(`errors.${data.error.data?.i18n}`) : data.error.message;

      toast.warn(msg);
      useBetStore()?.setTimeValue(false);
      if (data?.error?.data?.newPrices) {
        useBetStore()?.setErrorData(data.error.data);
      }
    }
  },
};

export const validationErrorInterceptor = {
  process(data: JsonRpcResponseMessage) {
    if (data.error?.code === 400 && data.error?.data?.validation) {
      throw new UnprocessableEntityError("Validation error", data?.error?.data?.validation, data);
    }
  },
};

export const authInterceptor = {
  process(data: JsonRpcResponseMessage) {
    if (data.error?.code === 401) {
      useAuth().logout();
      useBetStore().setTimeValue(false);
    }
  },
};

export const metaInterceptor = {
  process(data: JsonRpcResponseMessage) {
    if (data.result) {
      if (data.result.meta?.websiteMode === 0) {
        router.push({ name: "maintenance" });
      } else if (router.currentRoute.value.name === "maintenance") {
        router.push({ name: "/" });
      }
    }
  },
};
