import { toast } from "@/app/utils/notification"

export function validate(inputs, showNotifications = true) {
  let result = true
  let flag = true

  inputs.forEach((input) => {
    (input.rules || []).forEach((rule) => {
      result = rule(input.value)

      if (result !== true) {
        input.error = result
        flag = false
      }
      else {
        input.error = ""
      }
    })
  })

  if (flag !== true) {
    if (!showNotifications) {
      return result
    }
    // toast.error(result);
    return false
  }
  return true
}

export function setErrors(inputs, errors) {
  inputs.forEach((input) => {
    if (errors?.[input.name]) {
      input.error = errors[input.name]
    }
  })
}

export function resetErrors(inputs) {
  inputs.forEach((input) => {
    input.error = null
  })
}
