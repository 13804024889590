import { router } from "@/app/router";
import { useConfig } from "@/app/composables/useConfig";

const { geoblocked } = useConfig();

export function blocked(response) {
  if (response.headers["x-geoblocking"] === "blocked") {
    if (geoblocked.value === false) {
      geoblocked.value = true;
      router.push({ name: "geo-blocked" });
    }
  }

  if (!response.headers["x-geoblocking"] && geoblocked.value === true) {
    geoblocked.value = false;
  }

  console.log("Blocked", geoblocked.value);

  return response;
}
