<script setup>
import { computed } from "vue";
import { useBetStore } from "/store";
import AppSidebar from "@/app/components/sidebar-menu/AppSidebar.vue";
import { useConfig } from "@/app/composables/useConfig";
import AppFooter from "@/app/components/AppFooter.vue";
import AppHeader from "@/app/components/header/AppHeader.vue";
import BetSlipMobile from "@/sportsbook/components/betslip/BetSlipMobile.vue";
import TopButtonsMobile from "@/app/components/TopButtonsMobile.vue";

defineProps({
  categories: {
    type: Array,
    required: true,
  },
});

const { isDrawerOpen } = useConfig();

const store = useBetStore();

const isVisibleTopButtonMobile = computed(() => {
  if (store.getScreenWidth >= 568) {
    return false;
  }

  const modules = store.getModules;

  return modules.length > 1;
});
</script>

<template>
  <div class="big-wrapper">
    <AppHeader />
    <AppSidebar :categories="categories" />
    <div class="app-wrapper">
      <TopButtonsMobile v-if="isVisibleTopButtonMobile" />
      <div :class="isDrawerOpen ? 'app-container' : 'app-left app-container'">
        <slot />
      </div>
      <AppFooter />

      <BetSlipMobile class="tablet-only" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.big-wrapper {
  position: relative;
}
.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--be-bg);
  width: 100%;
  padding-top: 67px;
  min-height: 100vh;

  .app-container {
    display: flex;
    justify-content: center;
    height: 100%;
    max-width: 100%;
    margin: 10px 10px 0px 10px;
    margin-left: 76px;
    z-index: 1;

    .tablet & {
      overflow-x: hidden;
      margin: 0px;
    }
  }

  .app-left {
    margin-left: 290px;
  }
}

@media screen and (max-width: 500px) {
  .app-wrapper {
    min-height: 0px;
    .app-container {
      margin-right: 0px;
      margin-left: 0px;
      justify-content: start;
    }
  }
}
</style>
