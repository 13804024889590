<script setup>
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import CodeVerificationForm from "@/user-account/components/auth/CodeVerificationForm.vue"
import PhoneRegistrationForm from "@/user-account/components/auth/PhoneRegistrationForm.vue"
import PersonalInfoForm from "@/user-account/components/auth/RegistrationPersonalForm.vue"
import { useAuth } from "@/user-account/composables/useAuth"
import { useUserService } from "@/user-account/composables/useUserService"
import { rules as emailRules } from "@/user-account/utils/input-rules/email"
import { rules as general } from "@/user-account/utils/input-rules/password/general"
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone"
import { rules as referCodeRules } from "@/user-account/utils/input-rules/refer-code"
import { setErrors, validate } from "@/user-account/utils/input-rules/validation"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3"
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { useBetStore } from "../../../../store"
import { resendVerificationCode, signup, verifyCode } from "../../../user-account/api/index"

const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(["openLoginModal", "close"])

const { data, error, isLoading, getData } = useVisitorData(
  { extendedResult: true },
  { immediate: true },
)

onMounted(async () => {
  await getData()
})

const fingerPrintSealedResult = computed(() => {
  if (isLoading.value) {
    return "Loading..."
  }
  if (error.value) {
    return `Error: ${error.value.message}`
  }
  return data.value?.sealedResult || "No sealedResult available"
})

const { setToken } = useAuth()
const { setUser } = useUserService()

const router = useRouter()
const store = useBetStore()
const { geoblocked, registrationFlow } = useConfig()

const { t } = useI18n()
const requestId = ref()

const mode = computed(() => (requestId.value ? "verification" : "registration"))

const personalInfo = ref(false)
const registerData = ref([])

const inputs = ref([
  {
    name: "password",
    value: "",
    type: "password",
    label: t("auth.password"),
    placeholder: t("auth.password"),
    rules: general,
    iconName: "eye-opened",
    error: "",
  },
  {
    name: "referCode",
    value: "",
    type: "string",
    label: t("auth.referCode"),
    placeholder: t("auth.referCode"),
    rules: referCodeRules,
    error: "",
  },
])

watch(
  () => registrationFlow.value.type,
  (newType) => {
    if (["number_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "phone",
        value: "",
        type: "tel",
        label: t("auth.phone"),
        placeholder: t("auth.phone"),
        rules: phoneRules,
        error: "",
      })
    }
    if (["number_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "countryCode",
        value: "",
        type: "text",
      })
    }
    if (["email_2fa", "number_2fa_with_email", "email_2fa_with_number"].includes(newType)) {
      inputs.value.push({
        name: "email",
        value: "",
        type: "email",
        label: t("auth.email"),
        placeholder: t("auth.email"),
        rules: emailRules,
        error: "",
      })
    }
  },
  { immediate: true },
  { deep: true }, // Ensures the watcher runs initially to handle the current typeInfo value
)

async function register(form, form1) {
  if (form1 && !validate([...form, ...form1])) {
    return
  }
  else if (!form1 && !validate(form)) {
    return
  }

  if (registrationFlow?.value?.allow_personal_info && !form1) {
    personalInfo.value = true
    registerData.value = form
  }
  else {
    try {
      const formData = form.reduce((acc, field) => {
        acc[field.name] = field.value
        return acc
      }, {})

      const btag = localStorage.getItem("btag")

      if (btag) {
        formData.btag = JSON.parse(btag)
      }

      const { data: registerData1 } = await signup({
        ...formData,
        sealedResult: fingerPrintSealedResult.value,
        personalData: form1
          ? form1.reduce((acc, field) => {
              acc[field.name] = field.value
              return acc
            }, {})
          : undefined,
      })
      const { requestId: requestId1 } = registerData1
      if (requestId1) {
        requestId.value = requestId1
        store.setTime(getCurrentTimeInSeconds())
        personalInfo.value = false
      }
    }
    catch (error) {
      const { errors } = error || {}
      setErrors(form, errors)
      personalInfo.value = false
    }
  }
}

const timeLockedForSeconds = ref(0)

async function verify(form) {
  if (!validate(form)) {
    return
  }

  try {
    const { data: verifyCodeResponse } = await verifyCode({
      code: form[0].value.toString(),
      requestId: requestId.value,
    })

    const { token, user } = verifyCodeResponse
    if (token) {
      setToken(token)
      setUser(user)
      emit("close", false)
      requestId.value = null
      router.push({ path: "/" })
    }
  }
  catch (error) {
    const { errors, details } = error || {}
    if (details) {
      timeLockedForSeconds.value = details?.timeLockedForSeconds
    }
    if (errors) {
      setErrors(form, errors)
    }
  }
}

function openLogin() {
  emit("openLoginModal")
  emit("close", false)
}

async function resend() {
  try {
    const { data: resendVerificationCodeResponse } = await resendVerificationCode({
      requestId: requestId.value,
    })

    if (resendVerificationCodeResponse?.requestId) {
      store.setTime(60000)
      requestId.value = resendVerificationCodeResponse.requestId
    }
  }
  catch (error) {
    console.error(error)
  }
}

function getCurrentTimeInSeconds() {
  return Math.floor(Date.now() / 1000) // Convert milliseconds to seconds
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div
            class="close-btn"
            @click="
              () => {
                emit('close', false);
                if (mode === 'verification') {
                }
              }
            "
          >
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="">
          </div>

          <div v-if="geoblocked" class="modal-body">
            {{ t("geoblocked.message") }}
          </div>

          <div v-else class="modal-body">
            <PhoneRegistrationForm
              v-if="mode === 'registration' && !personalInfo"
              :inputs="inputs"
              @to-login="openLogin"
              @submit="register"
            />
            <PersonalInfoForm
              v-if="personalInfo"
              :register-data="registerData"
              @to-login="openLogin"
              @submit="register"
            />

            <CodeVerificationForm
              v-if="mode === 'verification'"
              :time-locked-for-seconds="timeLockedForSeconds"
              @submit="verify"
              @resend="resend"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: auto;
  position: relative;
  width: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.visitor-info {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  font-size: 14px;

  p {
    margin: 5px 0;
  }
}
</style>
