<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import CodeField from "@/app/components/ui/CodeField.vue"
import { useI18n } from "@/app/composables/useI18n"
import moment from "moment"
import { computed, onMounted, ref, watch } from "vue"
import { useBetStore } from "../../../../store"

const props = defineProps({
  timeLockedForSeconds: Number,
})

const emit = defineEmits(["submit", "resend"])

const { t } = useI18n()

const store = useBetStore()

watch(
  () => props.timeLockedForSeconds,
  (newVal) => {
    startTimer(newVal)
  },
)
const inputs = ref([
  {
    name: "code",
    value: "",
    type: "code",
    label: t("auth.code"),
    placeholder: t("auth.code"),
    rules: [],
    error: "",
  },
])

const counting = ref(true)
const time = ref(0)

function onResend() {
  emit("resend")
  counting.value = true
  startCounting()
}

function getCurrentTimeInSeconds() {
  return Math.floor(Date.now() / 1000) // Convert milliseconds to seconds
}

function calculateRemainingSeconds(startTimeInSeconds) {
  const currentTimeInSeconds = getCurrentTimeInSeconds()

  const timeDifference = currentTimeInSeconds - startTimeInSeconds

  if (timeDifference <= 60) {
    const remainingSeconds = 60 - timeDifference

    return remainingSeconds * 1000
  }
  else {
    counting.value = false
    return 0
  }
}

function onSumbit(inputs) {
  emit("submit", inputs)
  inputs[0].error = ""
}

function startCounting() {
  if (store.getTime === 60000) {
    time.value = calculateRemainingSeconds(getCurrentTimeInSeconds())
  }
  else {
    time.value = calculateRemainingSeconds(store.getTime)
  }
}

function onCountdownEnd() {
  // counting.value = false;
}

onMounted(() => {
  startCounting()
})

function updateTime(totalSeconds) {
  if (totalSeconds === 0) {
    counting.value = false
  }
  return true
}

const totalSeconds = ref(0) // this will be set from the API response
let intervalId = null

// Computed properties for minutes and seconds
const minutes = computed(() => {
  const min = Math.floor(totalSeconds.value / 60)
  return min < 10 ? `0${min}` : min
})

const seconds = computed(() => {
  const sec = totalSeconds.value % 60
  return sec < 10 ? `0${sec}` : sec
})

// Method to start the timer
function startTimer(time) {
  // Mock API response, replace this with your actual API call
  totalSeconds.value = time // Assume API response is 180 seconds

  intervalId = setInterval(() => {
    if (totalSeconds.value > 0) {
      totalSeconds.value--
    }
    else {
      clearInterval(intervalId)
    }
  }, 1000) // update every second
}
</script>

<template>
  <form class="login-view" @submit.prevent="onSumbit(inputs)">
    <div class="form-fields">
      <CodeField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
      />
    </div>
    <div>
      <BaseButton
        class="login-button alight wide high"
        type="submit"
        :disabled="
          !inputs[0].value
            || (props?.timeLockedForSeconds
              && props?.timeLockedForSeconds !== 0
              && minutes * 60 + seconds !== 0)
        "
      >
        {{
          props?.timeLockedForSeconds
            && props?.timeLockedForSeconds !== 0
            && minutes * 60 + seconds !== 0
            ? `${minutes}:${seconds}`
            : t("auth.validate")
        }}
      </BaseButton>
    </div>
    <div class="dont-have-account">
      <p>{{ t("general.code_not_received") }}</p>
    </div>
    <button class="resend" type="button" :disabled="counting" @click="onResend">
      <vue-countdown v-if="counting" v-slot="{ totalSeconds }" :time="time" @end="onCountdownEnd">
        {{ t("general.resend_again") }} {{ totalSeconds }} {{ t("general.seconds_later") }}

        <span v-if="time !== 0 ? updateTime(totalSeconds) : false" />
      </vue-countdown>
      <span v-else>{{ t("general.resend_code") }} </span>
    </button>
  </form>
</template>

<style lang="scss" scoped>
.registration-terms {
  font-size: 14px;
}

.resend {
  text-align: center;
  color: #3886ec;
  font-size: 14px;
  width: 100%;
}

.resend:disabled {
  color: grey;
  cursor: not-allowed;
}

.login-view {
  .forgot-password {
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    cursor: pointer;
    color: #3886ec;
    font-family: "Poppins";
    // transition: 0.4s all ease-in;
    // opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  .login-button {
    margin-top: 16px;
    // height: 3em;
  }
  .register-button {
    margin: 1em 0 3em;
    // height: 3em;
  }
  .dont-have-account {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
}
</style>
