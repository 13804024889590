<script setup>
import Banner from "@/app/components/Banner.vue"

import ProgressLoader from "@/app/components/ui/ProgressLoader.vue"
import { useLogoBanner } from "@/app/composables/useLogoBanner"
import CasinoPanel from "@/casino/components/CasinoPanel.vue"
import { computed, ref } from "vue"
import { fetchCategories, fetchProviders } from "../api"

const emit = defineEmits(["update:categories"])

const { banners } = useLogoBanner()

const categories = ref([])
const providers = ref([])
const loading = ref(false)

const filters = ref({
  search: "",
  providers: [],
  sort: "",
})

const currentPage = ref(1)
const lastPage = ref(1)

const expendedCategories = computed(() => {
  const defaultCategories = [
    { name: "Lobby", slug: "home", icon: "home" },
    { name: "Providers", slug: "providers", icon: "providers" },
  ]

  return [...defaultCategories, ...categories.value]
})

async function updateData(page = 1) {
  loading.value = true

  const categoryPromise = fetchCategories()
  const providerPromise = fetchProviders({ page, per_page: 20 })

  const [categoriesData, providersData] = await Promise.all([categoryPromise, providerPromise])

  categories.value = categoriesData.data.data
  providers.value = [...providers.value, ...providersData.data.data]
  currentPage.value = providersData.data.meta.current_page
  lastPage.value = providersData.data.meta.last_page

  emit("update:categories", categories.value)

  loading.value = false
}

updateData()

function onSearch(value) {
  filters.value.search = value
}

function onSort(value) {
  filters.value.sort = value
}

function onProviders(value) {
  filters.value.providers = value
}
</script>

<template>
  <article class="casino-view">
    <Banner :imgs="banners?.casino" />
    <ProgressLoader :loading="loading" />
    <CasinoPanel
      :categories="expendedCategories"
      :search-value="filters.search"
      @search="onSearch"
      @providers="onProviders"
      @sort="onSort"
    />

    <RouterView
      :categories="categories"
      :providers="providers"
      :filters="filters"
      :current-page="currentPage"
      :last-page="lastPage"
      @update:data="updateData"
    />
  </article>
</template>

<style scoped>
.casino-view {
  width: 100%;
  min-height: 70vh;
}

@media screen and (max-width: 500px) {
  .casino-view {
    padding: 0 14px;
  }
}
</style>
