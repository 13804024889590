<script setup>
import { useI18n } from "@/app/composables/useI18n"
import { countryMapping } from "../../../src/sportsbook/utils/general"

const emit = defineEmits(["close"])

const { locales, loadLanguage } = useI18n()

function changeLocale(langCode) {
  loadLanguage(langCode)
  emit("close", false)
}
</script>

<template>
  <div class="locales">
    <div
      v-for="(locale, index) in locales"
      :key="index"
      class="item"
      @click="changeLocale(locale.code)"
    >
      <img
        class="league-flag"
        :src="`/assets/images/flags/${countryMapping[locale.flag] || 'xx'}.svg`"
        alt=""
      >
      <span>
        {{ locale.name }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.dropdown-panel) {
  width: 240px !important;
  right: -4px;
  top: 52px;

  &:after {
    left: 209px;
  }
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #333360;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .block {
    width: initial;
    display: block;
    height: initial;
    background: transparent;
  }
}

.league-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .league-icon {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-bottom: 4px;
  }
}

.league-flag {
  width: 32px;
  height: 25px;
  border: 1px solid white;
}

.locales {
  margin: 20px 10px;

  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    span {
      margin-left: 25px;
    }
  }
  .item:hover{
    background:var(--be-divider)
  }
}
.locales{
  max-height: calc(100vh - 230px);
}
</style>
