<script setup>
import GameList from "@/casino/components/GameList.vue"
import { computed, ref, watch } from "vue"
import { onBeforeRouteUpdate } from "vue-router"
import { fetchCategory, fetchGames } from "../api"
import { useBetStore } from "/store.js"

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
})

const key = ref(0)
const category = ref(null)
const games = ref([])
const pagination = ref({
  page: 1,
  rowsPerPage: 100,
})

const store = useBetStore()

const isMobile = computed(() => {
  return store.getScreenWidth < 512
})

watch(
  [() => props.filters.search, () => props.filters.providers, () => props.filters.sort],
  async () => {
    pagination.value.page = 1
    games.value = []
    await updateData()
    key.value++
  },
  { deep: true },
)

async function loadGames($state) {
  try {
    const params = {
      page: pagination.value.page,
      per_page: pagination.value.rowsPerPage,
      categories: [category.value.id],
      device: isMobile.value ? "mobile" : "desktop",

    }

    if (props.filters.search) {
      params.search = props.filters.search
    }

    if (props.filters.sort) {
      params.sort = props.filters.sort
    }

    if (props.filters.providers.length) {
      params.providers = props.filters.providers
    }

    const { data } = await fetchGames(params)

    games.value.push(...data.data)

    if (data.data.length < pagination.value.rowsPerPage) {
      $state.complete()
    }
    else {
      $state.loaded()
    }

    pagination.value.page++
  }
  catch (error) {
    $state.error()
  }
};

async function updateData() {
  const { data } = await fetchCategory({ slug: props.slug })
  category.value = data.data
}

updateData()

onBeforeRouteUpdate(async (to) => {
  const { data } = await fetchCategory({ slug: to.params.slug })
  games.value = []
  pagination.value.page = 1
  category.value = data.data
  key.value++
})
</script>

<template>
  <article v-if="category">
    <GameList :key="key" :name="category.name" :games="games" @load="loadGames" />
  </article>
</template>
