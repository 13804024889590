<script setup></script>

<template>
  <div class="rules">
    <div class="main-title">
      <b>Welcome Bonus – Up to 130% Bonus + 200 Free Spins</b>
    </div>
    <div class="container">
      <p>We're excited to offer our <b class="bold-text">new players</b> an exclusive <b class="bold-text">welcome bonus</b> package of up to <b class="bold-text">€20,000 total max deposit and 200 free spins</b>.</p>

      <div class="main-section-title">
        <b>Deposit Bonus Breakdown</b>
      </div>
      <ul class="terms">
        <li><b class="bold-text">1st Deposit – 100% Bonus up to €5,000,</b> 40x rollover (20x real, 20x bonus), <b class="bold-text">20 Free Spins</b> on Bonanza Billions (BGaming).</li>
        <li><b class="bold-text">2nd Deposit – 130% Bonus up to €5,000,</b> 40x rollover (20x real, 20x bonus), <b class="bold-text">60 Free Spins</b> on Bonanza Billions (BGaming).</li>
        <li><b class="bold-text">3rd Deposit – 130% Bonus up to €5,000,</b> 40x rollover (20x real, 20x bonus), <b class="bold-text">60 Free Spins</b> on Bonanza Billions (BGaming).</li>
        <li><b class="bold-text">4th Deposit – 130% Bonus up to €5,000,</b> 40x rollover (20x real, 20x bonus), <b class="bold-text">60 Free Spins</b> on Bonanza Billions (BGaming).</li>
      </ul>

      <div class="main-section-title">
        <b class="bold-text">General Bonus Terms</b>
      </div>
      <ol class="numbered-list">
        <li>
          <div class="sub-section-title">
            <b class="bold-text">Eligibility & Activation</b>
          </div>
          <ul class="terms">
            <li>The <b class="bold-text">welcome bonus</b> is available to all <b class="bold-text">new players</b> who deposit using any available payment method.</li>
            <li>Free spins are credited <b class="bold-text">automatically</b> upon successful deposit and are valid for games specified in the promotion.</li>
          </ul>
        </li>

        <li>
          <div class="sub-section-title">
            <b class="bold-text">Wagering Requirements & Rollover</b>
          </div>
          <ul class="terms">
            <li>The <b class="bold-text">wagering requirement</b> for the deposit bonus is <b class="bold-text">40x</b> (20x real money + 20x bonus funds).</li>
            <li><b class="bold-text">Example:</b> If you deposit <b class="bold-text">€100</b>, you must place bets totaling <b class="bold-text">€4,000</b> before bonus funds become withdrawable.</li>
            <li>Free spin winnings are subject to the same rollover requirements.</li>
          </ul>
        </li>

        <li>
          <div class="sub-section-title">
            <b class="bold-text">Game Contribution & Rollover Coefficients</b>
          </div>
          <ul class="terms">
            <li>Different games contribute differently toward the rollover requirement.</li>
            <li><b class="bold-text">Slot games generally have a 1.0x coefficient</b> (100% of bets count towards the requirement).</li>
            <li><b class="bold-text">Live casino games (e.g., Blackjack, Roulette) often have a reduced coefficient (e.g., 0.10x)</b>, meaning only 10% of bets contribute to the rollover.</li>
            <li>The exact list of game contributions is dynamic and subject to change. Management reserves the right to adjust game coefficients based on internal policies.</li>
          </ul>
        </li>

        <li>
          <div class="sub-section-title">
            <b class="bold-text">Maximum & Minimum Bonus Limits</b>
          </div>
          <ul class="terms">
            <li><b class="bold-text">Minimum deposit:</b> €1 / $1 (or crypto equivalent).</li>
            <li><b class="bold-text">Maximum bonus:</b> €5,000 per deposit, up to a total of €20,000 across four deposits.</li>
            <li><b class="bold-text">Maximum winnings from bonus funds:</b> €1,000. Any amount exceeding this limit will be forfeited upon withdrawal.</li>
          </ul>
        </li>

        <li>
          <div class="sub-section-title">
            <b class="bold-text">Bonus Expiry & Restrictions</b>
          </div>
          <ul class="terms">
            <li>Bonuses must be <b class="bold-text">used within 30 days</b> of activation. Unused bonuses will be removed after this period.</li>
            <li>Bonuses can only be used on <b class="bold-text">'Bonus Games'</b> unless otherwise specified.</li>
            <li>Management reserves the right to move games in and out of the 'Bonus Games' category if necessary.</li>
          </ul>
        </li>

        <li>
          <div class="sub-section-title">
            <b class="bold-text">Withdrawals & Bonus Abuse</b>
          </div>
          <ul class="terms">
            <li>Players must complete the <b class="bold-text">full wagering requirement</b> before withdrawing bonus funds.</li>
            <li>Any violation of bonus terms may result in the removal of bonus funds.</li>
            <li>Maximum bonus fund winnings are capped at <b class="bold-text">$20,000</b>. Any winnings above this amount will be forfeited.</li>
            <li>In the event of a dispute, <b class="bold-text">all management decisions are final</b>.</li>
          </ul>
        </li>
      </ol>

      <hr class="divider">

      <div class="main-section-title">
        <b class="bold-text">Additional Notes</b>
      </div>
      <ul class="terms bullet-list">
        <li><b class="bold-text">Cryptocurrency deposits are eligible for bonuses.</b> The <b class="bold-text">€20,000 bonus cap applies to all currency equivalents</b> (BTC, ETH, USDT, etc.).</li>
        <li><b class="bold-text">Bonus funds are deducted first</b> when placing bets in 'Bonus Games'.</li>
        <li><b class="bold-text">Management reserves the right to modify or cancel promotions at any time.</b></li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rules {
  font-family: Poppins-reg;
  font-size: 14px;
  height: 400px;
  overflow: auto;
  margin-top: 30px;
}

.bold-text {
  font-family: Poppins-bold;
}

.main-title {
  font-family: Poppins-bold;
  font-size: 16px;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
}

.section-title {
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
}

.main-section-title {
  font-weight: bold;
  font-family: Poppins-bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.sub-section-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.terms {
  list-style-type: disc;
  padding-left: 20px;
}

.terms li {
  margin-bottom: 10px;
}

.numbered-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.numbered-list > li {
  margin-bottom: 20px;
}

.divider {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
  width: 100%;
}

.bullet-list li {
  position: relative;
  padding-left: 5px;
}
</style>
