<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { useI18n } from "@/app/composables/useI18n";

const props = defineProps({
  show: Boolean,
  back: Boolean,
});
const emit = defineEmits(["close", "confirm"]);
const { t } = useI18n();
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div
            v-if="back"
            class="back-btn"
            @click="
              () => {
                // emit('openRegisterModal', false);
                emit('close', false);
              }
            "
          >
            <img src="/assets/images/left-icon.png" class="left-icon" alt="" />
            <div>{{ t("general.return_register") }}</div>
          </div>
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="" />
          </div>

          <div class="modal-body">
            <img src="/assets/images/submit.svg" class="submit-icon" alt="" />
            <div>You have successfully entered.</div>
            <div>You can edit your picks up</div>
            <div class="base-bt">until 1 hour before Round 1 game starts.</div>
            <BaseButton class="alight wide high" @click="() => emit('confirm', false)">
              Submit
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}

h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}

.submit-icon {
  display: block;
  margin: 0px auto 30px auto;
}

.base-bt {
  margin-bottom: 30px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.back-btn {
  color: white;
  position: absolute;
  top: -34px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  cursor: pointer;
  .left-icon {
    width: 30px;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: auto;
  position: relative;
  width: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
  text-align: center;

  .login-view {
    .forgot-password {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      justify-content: end;
      cursor: pointer;
      color: #3886ec;
      font-family: "Poppins";

      // transition: 0.4s all ease-in;
      // opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .login-button {
      margin-top: 16px;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }

    .login-now {
      text-align: center;
      color: var(--be-primary-btn);
      cursor: pointer;
    }

    .register-button {
      margin: 1em 0 3em;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 0.9em;
      margin-bottom: 10px;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
