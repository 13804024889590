import client from "@/services/rest/client"

const auth = "/auth/"

export function sendFingerprint(data) {
  const method = "post"
  const url = "/fingerprints"

  return client({ method, url, data })
}

export function fetchCurrentUser() {
  const method = "get"
  const url = "/self"

  return client({ method, url })
}

export function saveIpGeoLocation(data) {
  const method = "post"
  const url = "/ip-geolocations"

  return client({ method, url, data })
}

export function signin(data) {
  const method = "post"
  const url = `${auth}signin`

  return client({ method, url, data })
}

export function getSurvivors(data) {
  const method = "get"
  const url = "/survivor-competitions"

  return client({ method, url, data })
}

export function getSurvivor(id) {
  const method = "get"
  const url = `/survivor-competitions/${id}/predictions`

  return client({ method, url })
}

export function postSurvivor(id, data) {
  const method = "post"
  const url = `/survivor-competitions/${id}/predictions`

  return client({ method, url, data })
}

export function signup(data) {
  const method = "post"
  const url = `${auth}signup`

  return client({ method, url, data })
}
export function verifyCode(data) {
  const method = "post"
  const url = `${auth}verify-code`

  return client({ method, url, data })
}

export function verify2fa(data) {
  const method = "post"
  const url = `${auth}verify-2fa`

  return client({ method, url, data })
}
export function resetPassword(data) {
  const method = "post"
  const url = `${auth}reset-password`

  return client({ method, url, data })
}
export function resendVerificationCode(data) {
  const method = "post"
  const url = `${auth}resend-verification-code`

  return client({ method, url, data })
}

export function verifyPasswordReset(data) {
  const method = "post"
  const url = `${auth}verify-password-reset`

  return client({ method, url, data })
}
export function resendPasswordResetCode(data) {
  const method = "post"
  const url = `${auth}esend-password-reset-code`

  return client({ method, url, data })
}
export function changePassword(data) {
  const method = "post"
  const url = `${auth}change-password`

  return client({ method, url, data })
}

export function getQRCode(data) {
  const method = "get"
  const url = `${auth}get-qrcode`

  return client({ method, url, data })
}

export function enable2fa(data) {
  const method = "post"
  const url = `${auth}enable-qrcode`

  return client({ method, url, data })
}

export function disable2fa(data) {
  const method = "post"
  const url = `${auth}disable-qrcode`

  return client({ method, url, data })
}

export function updateProfile(data) {
  const method = "post"
  const url = "/profile"

  return client({ method, url, data })
}

export function getProfile(data) {
  const method = "get"
  const url = "/profile"

  return client({ method, url, data })
}

export function fetchCountriesData(data) {
  const method = "get"
  const url = "/public/countries?per_page=1000"

  return client({ method, url, data })
}
