<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { router } from "@/app/router"
import { computed } from "vue"

const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
  isLobby: {
    type: Boolean,
    default: false,
  },
})

function onProviderClick(slug) {
  router.push({
    name: "casino.provider",
    params: { slug },
  })
}

const hasImage = computed(() => {
  return props.provider.images.length > 0
})

const imageUrl = computed(() => {
  return props.provider.images[0].url
})
</script>

<template>
  <div class="provider-group" :class="{ 'provider-group-lobby': isLobby }" @click="onProviderClick(provider.slug)">
    <div class="provider" :class="{ 'provider-lobby': isLobby }" :title="provider.name">
      <img v-if="hasImage" :src="`${imageUrl}`" class="image">
      <div v-else class="unknown">
        <BaseIcon name="null" color="var(--be-secondary-text-color)" />
        <div class="provider-name">
          {{ provider.name }}
        </div>
      </div>
    </div>
    <div class="game-count">
      <BaseIcon name="games" color="var(--be-secondary-text-color)" size="16" />
      <div class="game-heading">
        Games
      </div>
      <div class="count">
        {{ provider.gamesCount }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.provider-group {
  display: flex;
  flex-direction: column;
}

.provider {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--be-small-card-bg);
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  padding: 5px 10px;
  aspect-ratio: 15/11;
  .mobile & {
    aspect-ratio: 15/11;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
    padding: 2px 5px;
  }
}

.provider-group-lobby {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  min-width: 120px;
  max-width: 120px;
}

.provider-lobby {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--be-small-card-bg);
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  padding: 5px 10px;
  height: 88px;
  .mobile & {
    height: 88px;
  }
  .image {
    max-width: 100%;
    max-height: 100%;
    padding: 2px 5px;
  }
}

.game-count {
  display: flex;
  gap: 0 10px;
  justify-content: center;
  font-size: 12px;
  font-family: Rubik-med;
  align-items: center;
  padding: 7px 0px;
  background: var(--be-secondary-btn);
  border-radius: 0 0 5px 5px;
}

.game-heading {
  color: var(--be-secondary-text-color);
  font-family: Rubik;
}

.unknown {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Rubik-med;
  font-size: 12px;
  gap: 10px;
}

.provider-name {
  text-align: center;
  overflow-wrap: anywhere;
}
</style>
