<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { useI18n } from "@/app/composables/useI18n"
import { rules } from "@/user-account/utils/input-rules/otp"
import { computed, ref } from "vue"

const emit = defineEmits(["submit"])

const { t } = useI18n()

const inputs = ref([
  {
    name: "code",
    value: "",
    type: "text",
    label: t("auth.2fa_code"),
    placeholder: t("auth.enter_2fa_code"),
    rules,
    error: "",
  },
])

function onSubmit() {
  emit("submit", inputs.value)
  inputs.value[0].error = ""
}
</script>

<template>
  <form class="login-view" @submit.prevent="onSubmit">
    <div class="form-fields">
      <BaseTextField
        v-model="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :error="inputs[0].error"
        @error="inputs[0].error = $event"
      />
    </div>
    <div>
      <BaseButton
        class="login-button alight wide high"
        type="submit"
        :disabled="!inputs[0].value"
      >
        {{ t("auth.verify") }}
      </BaseButton>
    </div>
    <div class="description">
      <p>{{ t("auth.enter_2fa_code_description") }}</p>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.login-view {
  .form-fields {
    margin-bottom: 1rem;
  }

  .login-button {
    margin-top: 16px;
  }

  .description {
    font-size: 14px;
    margin: 16px 0;
    text-align: center;
  }
}
</style>
