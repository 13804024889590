<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useConfig } from "@/app/composables/useConfig"

import { t } from "@/app/composables/useI18n"
import Manage2FA from "@/user-account/components/security/Manage2FA.vue"
import { computed } from "vue"
import Limits from "./Limits.vue"
import LoginInfo from "./LoginInfo.vue"
import PersonalInfo from "./PersonalInfo.vue"
import selfExclusion from "./selfExclusion.vue"
import { useBetStore } from "/store"

const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(["close"])
const store = useBetStore()

const { registrationFlow } = useConfig()

const tabs = computed(() => {
  const baseTabs = [
    { value: "loginInfo", title: "login_info", icon: "edit-info" },
    { value: "limits", title: "limits_self", icon: "limits" },
    { value: "2fa", title: "2fa_add", icon: "limits" },
  ]

  const hasValidPersonalInfo = computed(() => {
    if (!registrationFlow.value?.allow_personal_info) {
      return false
    }

    const personalInfoData = registrationFlow.value?.personal_info_data
    return personalInfoData && Object.values(personalInfoData).includes(true)
  })

  if (
    hasValidPersonalInfo.value
    || registrationFlow.value.type === "number_2fa_with_email"
    || registrationFlow.value.type === "email_2fa_with_number"
  ) {
    baseTabs.splice(1, 0, {
      value: "personalInfo",
      title: "personal_info",
      icon: "kyc",
    })
  }

  return baseTabs
})

const iconBase = "/assets/images/bets/account-details/"

function onTabClick(tab) {
  store.setAccountTab(tab)
}

const selectedTab = computed(() => {
  return store.getAccountTab
})
</script>

<template>
  <div class="account-details">
    <div class="account-details__options">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="`btn ${selectedTab === tab.value && 'active-btn'}`"
        @click="onTabClick(tab.value)"
      >
        <BaseIcon
          :name="tab.icon"
          size="17"
          :color="
            selectedTab === tab.value
              ? 'var(--be-primary-btn-text-label)'
              : 'var( --be-primary-text-color-opacity)'
          "
        />

        <div>{{ t(`general.${tab.title}`) }}</div>
      </div>
    </div>
    <div v-if="selectedTab === 'loginInfo'">
      <LoginInfo />
    </div>
    <div v-if="selectedTab === 'personalInfo'">
      <PersonalInfo />
    </div>
    <div v-if="selectedTab === 'limits'">
      <Limits />
      <selfExclusion />
    </div>

    <div v-if="selectedTab === '2fa'">
      <Manage2FA />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-details {
  border-radius: 8px;
  margin: 0 16px;

  .account-details__options {
    background: var(--be-big-card-bg);
    border-radius: 8px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 1rem;
    .btn {
      color: rgba(255, 255, 255, 0.3);
      display: flex;
      flex-direction: column;
      gap: 5px 0;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      cursor: pointer;
      width: 33%;
      padding: 9px 0;
      text-align: center;
    }
    .active-btn {
      border-radius: 6px;
      color: var(--be-primary-btn-label);
      background: var(--be-primary-btn);
      box-shadow: 0px 0px 10px 0px rgba(116, 62, 232, 0.5);
    }
  }
}
@media screen and (max-width: 550px) {
  .btn {
    font-size: 9px;
  }
}
</style>
