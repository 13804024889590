import { ref } from "vue"

const geoblocked = ref(false)
const systemSettings = ref(false)
const registrationFlow = ref(false)
const isDrawerOpen = ref(true)
const appDivisions = ref([])
const appDivision = ref()
const btcRate = ref(0)
const footerFiles = ref({})

export function useConfig() {
  function setSystemSettings(_systemSettings) {
    systemSettings.value = _systemSettings

    appDivisions.value = ((_systemSettings.divisions as any[]) ?? [])
      .filter(division => division.active)
      .map(division => division.name)
  }

  function setRegistrationFlow(_registrationFlow) {
    registrationFlow.value = _registrationFlow
  }

  function setBtcRate(rate) {
    btcRate.value = rate
  }

  function setFooterFiles(_footerFiles) {
    footerFiles.value = _footerFiles
  }

  return {
    appDivision,
    appDivisions,
    isDrawerOpen,
    geoblocked,
    systemSettings,
    setSystemSettings,
    setBtcRate,
    setFooterFiles,
    footerFiles,
    btcRate,
    registrationFlow,
    setRegistrationFlow,
  }
}
