<script setup lang="ts">
import { useConfig } from "@/app/composables/useConfig"
import { useCurrencies } from "@/app/composables/useCurrencies"
import { useUserService } from "@/user-account/composables/useUserService"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { useBetStore } from "../../../store"

const screenWidth = ref(window.innerWidth)

const { registrationFlow } = useConfig()
const { getIconByTicker } = useCurrencies()

function handleResize() {
  screenWidth.value = window.innerWidth
}

const store = useBetStore()

onMounted(() => {
  window.addEventListener("resize", handleResize)
  handleResize() // Initial check
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize)
})

const { activeWallet, user } = useUserService()

function handleShowModalWallet() {
  store.handleShowBetModal()
  store.setSection("wallet")
  store.setWalletTab("balance")
}
</script>

<template>
  <div class="wallet-container">
    <div class="user-wallet-section" @click="handleShowModalWallet">
      <div v-if="screenWidth >= 568" class="user-section">
        <img src="/assets/images/user-icon.svg" alt="">
      </div>
      <div
        class="user-balance-section"
        :class="{ 'left-rounded': screenWidth < 568 }"
      >
        <div class="user-name">
          {{
            ["number_2fa", "number_2fa_with_email"].includes(registrationFlow.type)
              ? `+${user?.phone}`
              : user?.email
          }}
        </div>
        <div class="user-balance">
          <div style="display: flex; align-items: center">
            <div>
              {{ activeWallet?.bonusBalance || activeWallet?.balance }}
            </div>
            <img
              :src="getIconByTicker(activeWallet?.currency?.ticker)"
              class="currency-icon"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wallet-container {
  position: relative;
}

.currency-icon {
  margin-left: 9px;
  width: 13px;
  height: 13px;
}
.user-wallet-section {
  display: flex;
  height: 40px;

  .user-balance-section {
    background: var(--be-big-card-bg);
    padding: 1px 0px 2px 8px;
    width: 180px;
    cursor: pointer;

    &.left-rounded {
      border-radius: 8px 0 0 8px;
    }

    @media screen and (max-width: 500px) {
      &.left-rounded {
        border-radius: 6px;
      }
    }

    .user-name {
      color: var(--be-secondary-text-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 3px;
      font-family: Rubik;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
    .user-balance {
      display: flex;
      gap: 0px 5px;
      color: var(--be-primary-text-color);
      font-size: 14px;
      justify-content: space-between;
      margin-right: 10px;
      font-size: 14px;
      font-family: Rubik-med;
    }
  }

  .user-section {
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    background: var(--be-secondary-btn);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }

  .wallet-section {
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    background: linear-gradient(90deg, #3886ec 0%, #544fe5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }
}

.user_wallet-drop-down {
  border-radius: 8px;
  position: absolute;
  background: #0d0c2c;
  top: 50px;
  padding: 8px;
  font-size: 12px;

  width: 100%;
  .wallet-drop-item {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.5;

    &.item-active {
      background: #1a1a3a;
      opacity: 1;
    }

    .item-right {
      display: flex;
      gap: 0 5px;
    }
  }

  .free-bet {
    border: 1px solid white;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    opacity: 0.7;
  }

  .free-bet-header {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0 5px;
    margin-bottom: 5px;
  }

  .free-bet-description {
    font-size: 10px;
    font-weight: normal;
    text-align: right;
  }
}
</style>
