<script setup>
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"

const { appDivisions, appDivision } = useConfig()

const { t } = useI18n()
</script>

<template>
  <div v-if="appDivisions.length > 1" class="division-links">
    <router-link
      v-for="division in appDivisions"
      :key="division"
      class="division-link"
      :class="{ active: division === appDivision }"
      :to="{ name: division }"
    >
      <div :class="division === appDivision ? 'active-tab' : 'non-active-tab '" />
      <span class="division">{{ t(`general.${division}`) }}</span>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.division-links {
  margin-left: 1em;
  height: 100%;
  display: flex;

  .division {
    display: flex;
    align-items: center;
    height: calc(100% - 10px);
    font-family: Rubik-med;
    font-size: 16px;
  }

  .division-link {
    position: relative;
    padding: 0 0.6em;
    opacity: 1;
    color: var(--be-disable-state);

    &.active {
      color: var(--be-primary-text-color);
    }
    .active-tab {
      border-radius: 0px 0px 10px 10px;
      border: 2.5px solid var(--be-primary-btn);
    }

    .non-active-tab {
      height: 5px;
    }
    &:last-child::after {
      display: none;
    }
    .tablet &,
    .mobile & {
      display: none;
    }
  }
}
</style>
