<script setup>
import { useI18n } from "@/app/composables/useI18n"

const props = defineProps({
  game: {
    type: Object,
    default: null,
  },
})

const { t } = useI18n()
</script>

<template>
  <picture>
    <img :src="game.images[0].url" class="game-image">
  </picture>
  <div class="game-hover-wrapper">
    <div class="top" />
    <div class="center">
      <div class="play-button">
        {{ t("casino.play") }}
      </div>
    </div>
    <div class="bottom">
      <div class="game-title">
        {{ props.game.name }}
      </div>
      <div class="game-provider">
        {{ props.game.provider.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game-image {
  max-width: 100%;
  border-radius: 5px;
  height: 161px;
}

.game-hover-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 24, 36, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 1;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  .bottom {
    padding: 8px;
    flex: 1;
  }

  .center {
    padding: 8px;
    text-align: center;
    flex: 1;

    .play-button {
      display: inline;
      background-color: var(--be-primary-btn);
      border: 1px solid var(--be-primary-btn);
      color: var(--be-primary-btn-label);
      border-radius: 8px;
      font-size: 14px;
      font-family: Rubik-med;
      padding: 8px 15.4px;
      box-shadow: 0 0 0 0 rgba(72, 255, 145, 0.3);
      cursor: pointer;
    }
  }

  .top {
    padding: 8px;
    flex: 1;
  }

  .game-title {
    font-family: Rubik, sans-serif;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    font-size: 15px;
    font-weight: 400;
  }

  .game-provider {
    font-family: Rubik, sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  .play-button {
    display: block;
    padding-top: 5px;
    text-align: center;
    width: 40px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
