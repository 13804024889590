<script setup>
import { computed } from "vue";
import { useBetStore } from "../../../store";
import GameCardImage from "./GameCardImage.vue";
import GameCardBlank from "./GameCardBlank.vue";
import { router } from "@/app/router";
import { useAuth } from "@/user-account/composables/useAuth";

const props = defineProps({
  game: {
    type: Object,
    default: null,
  },
});
const store = useBetStore();
const { isAuthenticated } = useAuth();

function onGameClick(game) {
  if (!isAuthenticated()) {
    store.handleShowLogin();
  } else {
    router.push({
      name: "casino.game",
      params: { slug: game.slug },
    });
  }
}

const hasImage = computed(() => {
  return props.game.images.length > 0;
});
</script>

<template>
  <div class="game" :data-game-id="game.id" @click="onGameClick(game)">
    <GameCardImage v-if="hasImage" :game="game" />
    <GameCardBlank v-else :game="game" />
  </div>
</template>

<style lang="scss" scoped>
.game {
  display: flex;
  position: relative;
  width: 120px;
  height: auto;
  border-radius: 5px;
  cursor: pointer;

  .tablet & {
    width: 120px;
  }
}

.games-grid {
  .game {
    width: auto;
    max-width: 240px;
  }
}
</style>
