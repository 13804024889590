<script setup>
import { ref } from "vue";
import { brands } from "@/app/utils/brands";

const props = defineProps({
  title: {
    type: String,
  },
  body: {
    type: String,
  },
  suffix: {
    type: String,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
});

const screenWidth = ref(window.innerWidth);
const { logo } = brands[import.meta.env.VITE_APP_BRAND];
</script>

<template>
  <div class="simple-page">
    <button
      v-if="props.showBackButton"
      type="button"
      class="close-button"
      @click="$router.replace('/')"
    />
    <div>
      <img v-if="screenWidth < 766" :src="logo" alt="BetKudos" />

      <h2>{{ props.title }}</h2>
      <p>
        {{ props.body }}
      </p>
      <h4>
        {{ props.suffix }}
      </h4>
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.simple-page {
  padding-top: 5em;
  .notebook &,
  .desktop & {
    width: 70%;
  }
  p {
    margin: 4em 0 2em;
  }
  .close-button {
    position: absolute;
    top: 2em;
    right: 2em;
  }
}
</style>
