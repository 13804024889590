<script>
import SimplePage from "./SimplePage.vue";
import { capitalizeString } from "@/sportsbook/utils/general";

export default {
  name: "GeoBlockedView",
  components: { SimplePage },
  data() {
    return {
      brandName: capitalizeString(import.meta.env.VITE_APP_BRAND),
    };
  },
};
</script>

<template>
  <SimplePage
    title="Your Region is Geo-Blocked :("
    :body="`You have accessed ${brandName} from a region that is prohibited under our Licencing agreement.`"
    suffix="Are you using a VPN or proxy? If so, try turning it off and refresh the page to be able to login."
    :show-back-button="true"
  />
</template>

<style scoped></style>
