<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js"
import { camelToSnakeCaseWithNumbers } from "@/app/utils/strings"
import { fetchCountriesData } from "@/user-account/api"

import { rules as RequiredRules } from "@/user-account/utils/input-rules/generic"
import { rules as passwordRules } from "@/user-account/utils/input-rules/password"
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone"
import { computed, ref, watch } from "vue"

const props = defineProps({
  registerData: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(["submit", "toLogin"])

const { registrationFlow } = useConfig()

const { t } = useI18n()

const countries = ref([])

const inputs = ref([])
const displayOrders = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "city",
  "addressLine1",
  "addressLine2",
  "postalCode",
]

watch(
  registrationFlow.value.personal_info_data,
  (data) => {
    displayOrders.forEach((item) => {
      if (data[item]) {
        inputs.value.push({
          name: item,
          value: "",
          type: item == "dateOfBirth" ? "date" : "text",
          label: t(`kyc.${camelToSnakeCaseWithNumbers(item)}`),
          placeholder: t(`kyc.${camelToSnakeCaseWithNumbers(item)}`),
          rules: RequiredRules,
          error: "",
        })
      }
    })
  },
  { immediate: true }, // Ensures the watcher runs initially to handle the current typeInfo value
)

function onLogin() {
  emit("toLogin")
}

const countryCodes = computed(() => {
  return countries.value.data?.filter(country => country.status).map(country => country.code)
})

async function fetchCountries() {
  try {
    const res = await fetchCountriesData()
    countries.value = res.data
  }
  catch (error) {
    console.error("Error fetching countries:", error)
  }
}

fetchCountries()
</script>

<template>
  <form @submit.prevent="$emit('submit', registerData, inputs)">
    <div class="form-fields">
      <BaseTextField
        v-for="(input, key) in inputs"
        :model-value="input.value"
        :rules="input.rules"
        :label="input.label"
        :placeholder="input.placeholder"
        :type="input.type"
        :icon-name="input.iconName"
        :error="input.error"
        @update:model-value="input.value = $event"
        @error="input.error = $event"
      />
    </div>

    <div class="terms">
      <a href="/docs/t&c.pdf" target="_blank">
        {{ t("account.registration_age_term") }}
      </a>
    </div>

    <BaseButton class="submit alight wide high" type="submit">
      {{ t("auth.register") }}
    </BaseButton>

    <div class="dont-have-account">
      <p>
        {{ t("account.already_account_label") }}
      </p>
    </div>

    <button class="login-now" type="button" @click="onLogin">
      {{ t("account.login_now_label") }}
    </button>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.terms {
  font-size: 14px;
  margin-bottom: 1rem;
}

.login-now {
  text-align: center;
  color: var(--be-primary-btn);
  font-size: 14px;
  width: 100%;
}
.dont-have-account {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
