<script setup lang="ts">
import type { ButtonHTMLAttributes, PropType } from "vue"
import { router } from "@/app/router"
import { computed } from "vue"

const props = defineProps({
  to: {
    type: [String, Object],
    default: "",
  },
  type: {
    type: String as PropType<ButtonHTMLAttributes["type"]>,
    default: "button",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  slug: {
    type: String,
  },
  games_number: {
    type: Number,
  },
})
const emit = defineEmits(["click"])
const isShow = computed(() => props.slug !== "home" && props.slug !== "providers")

function click(event) {
  if (!props.to) {
    emit("click", event)
    return
  }
  const to = typeof props.to === "object" ? props.to : { name: props.to }
  router.push(to)
}
</script>

<template>
  <button
    v-if="!isShow || games_number !== 0"
    :class="{ disabled }"
    class="base-button"
    :type="type"
    :disabled="disabled"
    @click="click"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
@use "@/brands/common/css/BaseButton.scss" as *;
.base-button {
  color: var(--be-primary-btn-label);
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border-radius: 4px;
  font-weight: bold;
  min-height: 36px;
  min-width: 55px;
  padding: 0 16px;

  &:not(.text) {
    opacity: 0.9;
    background-color: var(--be-primary-btn);
  }

  &.active {
    opacity: 1 !important;
  }

  &.alight {
    // background-image: linear-gradient(to right, #0686f3, #5a45ed);
    background: var(--be-primary-btn);
  }
  &.high {
    min-height: 44px;
  }

  &:hover {
    opacity: 1;
    // background-color: var(--be-small-card-bg);
  }
  &.category:hover {
    background: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.wide {
    width: 100%;
  }
}
</style>
