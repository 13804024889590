<script setup>
import ContentLoader from "@/app/components/ContentLoader.vue"
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import GameActions from "@/casino/components/GameActions.vue"
import { useGameHelper } from "@/casino/composables/useGameHelper.js"
import { api } from "@/services"
import { useUserService } from "@/user-account/composables/useUserService"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useBetStore } from "../../../store"
import { fetchGame } from "../api"

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
})

const route = useRoute()
const store = useBetStore()
const { isDrawerOpen } = useConfig()

const { activeWallet, bonusWallet, user } = useUserService()

const { t } = useI18n()
const { startGame, isBonusGame, selectedCurrency, loading } = useGameHelper()
const exchangeRates = ref([])

const isFullscreen = ref(false)
const game = ref(null)

const bettingAllowed = computed(() => {
  if (!user.value.betting) {
    return false
  }

  return !["banned", "investigating"].includes(user.value.status)
})

const userBalance = computed(() => {
  const balance = activeWallet.value.balance
  const balanceCurrency = activeWallet.value.currency.ticker
  const exchangeRate = exchangeRates.value.find(
    rate =>
      rate.from === balanceCurrency && rate.to === selectedCurrency.value,
  )
  if (!exchangeRate) {
    return "N/A"
  }

  return (Math.round(exchangeRate.rate * balance * 100) / 100).toFixed(2)
})

const bonusBalance = computed(() => {
  if (!isBonusGame(game)) {
    return null
  }

  if (!bonusWallet.value) {
    return null
  }

  const balance = bonusWallet.value.balance

  const balanceCurrency = bonusWallet.value.currency.ticker
  const exchangeRate = exchangeRates.value.find(
    rate =>
      rate.from === balanceCurrency && rate.to === selectedCurrency.value,
  )
  if (!exchangeRate) {
    return "N/A"
  }

  return (Math.round(exchangeRate.rate * balance * 100) / 100).toFixed(2)
})

const gameCurrencies = import.meta.env.VITE_APP_GAME_CURRENCIES?.split(",")

function exitGame() {
  game.value.redirectUrl = null
}

function onPlay(isDemo) {
  startGame(game, isDemo, store.handleShowLogin)
}

// Function to reset scroll position
function resetScrollPosition() {
  window.scrollTo(0, 0)
}

async function fetchData() {
  const { data } = await fetchGame(props.slug)

  game.value = data.data

  selectedCurrency.value = gameCurrencies[0]
  exchangeRates.value = await api.utils.fetchExchangeRates()

  if (route.query.action === "play") {
    selectedCurrency.value = route.query.currency
    onPlay(false)
  }
  resetScrollPosition()

  if (window.innerWidth < 500) {
    watch(
      () => game.value.redirectUrl,
      async (url) => {
        if (url) {
          window.location.href = url
        }
      },
    )
  }
}

onMounted(() => {
  resetScrollPosition()
  fetchData()
})
</script>

<template id="game">
  <ProgressLoader :loading="loading" />
  <article
    v-if="game?.name"
    class="game-lobby"
    :class="{ fullscreen: isFullscreen }"
  >
    <div
      v-if="!game.redirectUrl"
      class="lobby-content"
      :style="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${game.image})`,
      }"
    >
      <h1 class="game-name">
        {{ game.name.replace("Mobile", "") }}
      </h1>
      <h4 class="provider">
        {{ game.provider.name }}
      </h4>
      <div class="currency-switch">
        <div id="clearcur" class="balance-header">
          <table class="balance-info">
            <tbody>
              <tr>
                <td>{{ t("finance.selected_currency") }}:</td>
                <td class="value">
                  {{ activeWallet.currency.ticker }}
                </td>
              </tr>
              <tr>
                <td>{{ t("finance.balance_in") }} {{ selectedCurrency }}:</td>
                <td class="value">
                  {{ userBalance }}
                </td>
              </tr>
              <tr v-if="bonusBalance !== null">
                <td>{{ t("finance.bonus_in") }} {{ selectedCurrency }}:</td>
                <td class="value">
                  {{ bonusBalance }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="currency-buttons">
          <div v-for="(gameCurrency, i) in gameCurrencies" :key="i">
            <BaseButton
              class="currency-button"
              :class="{ selected: selectedCurrency === gameCurrency }"
              @click="selectedCurrency = gameCurrency"
            >
              <div class="button-content">
                <BaseIcon :name="gameCurrency.toLowerCase()" size="40" />
                <span>{{ gameCurrency }}</span>
              </div>
            </BaseButton>
          </div>
        </div>
      </div>
      <div class="play-buttons">
        <BaseButton
          class="play-button alight"
          :disabled="!bettingAllowed"
          @click="onPlay(false)"
        >
          {{ t("casino.play") }}
        </BaseButton>
        <BaseButton
          class="play-button"
          :disabled="!bettingAllowed"
          @click="onPlay(true)"
        >
          {{ t("casino.play_demo") }}
        </BaseButton>
      </div>
      <div v-if="!bettingAllowed" class="alert">
        Your account is under review. We'll update you soon.
      </div>
    </div>
    <div v-else class="game-wrapper">
      <GameActions
        v-model:is-fullscreen="isFullscreen"
        :game="game"
        class="lobby-actions"
        :class="
          isFullscreen ? `${isDrawerOpen ? 'full-screen' : 'drawer-open'}` : ''
        "
      />
      <iframe
        id="game-iframe"
        :src="game.redirectUrl"
        frameborder="0"
        allowfullscreen
        target="_parent"
        :class="
          isFullscreen
            ? `game-iframe ${isDrawerOpen ? 'full-screen' : 'drawer-open'}`
            : 'game-iframe'
        "
        @exitGame="exitGame"
      />
    </div>
  </article>
  <ContentLoader v-else />
</template>

<style lang="scss" scoped>
.alert {
  background: #ff0000;
  color: #fff;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  max-width: 300px;
  border-radius: 0.3rem;
}

.currency-buttons {
  display: flex;
}

.full-screen {
  width: calc(100% - 65px) !important;
  margin-left: 61px !important;
}

.drawer-open {
  width: calc(100% - 280px) !important;
  margin-left: 275px !important;
}
.game-lobby {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .desktop & {
    width: 90%;
  }
  .lobby-content {
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
    width: 100% !important;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
      0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;

    .game-name {
      font-family: var(--be-header-font);
      font-size: 2.3em;
    }
    .provider {
      margin: 0.2em 0 3em;
    }
    .currency-switch {
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      .balance-header {
        margin-bottom: 16px;
        .balance {
          opacity: 0.8;
          text-align: center;
        }
        .balance-info {
          .value {
            text-align: right;
            padding-left: 10px;
          }
        }
      }

      .currency-button {
        background: transparent;
        border: #ffffff63 1px solid;
        color: #ffffff63;
        padding-top: 5px !important;
        height: 95px;

        &:first-child {
          margin-right: 10px;
        }

        &.selected {
          background: #ffffff;
          border: #ffffff 1px solid;
          color: #000000;
        }

        .button-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .play-buttons {
      .play-button {
        width: 150px;
        height: 3em;
        margin: 3em 1em;
      }
    }
  }

  .game-wrapper {
    position: relative;
    width: 100%;
    .game-iframe {
      width: 100%;
      height: 80vh;
    }
  }

  &.fullscreen {
    .lobby-actions {
      z-index: 200;
      position: fixed;
      bottom: 0px;
    }
    .game-wrapper {
      width: 100vw;
    }
    .lobby-actions {
      width: 100vw;
    }

    .lobby-content,
    .game-iframe {
      position: fixed;
      inset: 0px;
      height: 100%;
      width: 100%;
      border: none;
      margin: 0px;
      padding: 0px;
      top: 66px;
      overflow: hidden;
      z-index: 100;
    }
  }
}
</style>
